@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.imgErrorContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2.5em;
    padding-bottom: 1em;
}

.errorIcon {
    color: $red;
    font-size: 50px;
}

.invalidTextContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.invalidTextBold {
    font-weight: bold;
    font-size: 18px;
}

.invalidText {
    font-size: 16px;
}

.okButton {
    display: none;
    padding: 10px;
    background-color: $light-green;
    border: 1px solid $light-green;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 1em;
    color: $white;
    cursor: pointer;

    span {
        padding-right: 5px;

        &::before {
            font-size: 1em;
        }
    }

    @media #{$tablet-up} {
        display: block;
    }
}