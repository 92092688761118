@import "../../global/colors.scss";

.userContainer {
  display: flex;
  flex: 6;
  gap: 0.5em;
}

.userInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  place-items: center;
  gap: 0.3em;
}

.smallSizeContainer{
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  font-size: 14px;
}

.icon {
  width: 100%;
  height: 1.5em;
  background-color: lightblue;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 400;
  color: white;
}

.name {
  font-size: 1em;
}

.dayContainer {
  padding: 7px 11px;
  background-color: $black;
  border-radius: 5px;
}

.id {
  font-size: 0.7em;
  font-weight: normal;
  color: $font-dark-grey;
}

.qualificationContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 3px;
}

.bullet {
  width: 7px;
  height: 7px;
  border-radius: 4px;
}

.userInfoClass{
  font-size: 1em;
  width: 60%;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}