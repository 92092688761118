@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.siteAndShiftBtn {
  display: none;
  padding: 10px;
  background-color: $light-green;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 0.85em;
  color: $white;
  font-family: 'Poppins';
  cursor: pointer;

  @media #{$tablet-up} {
    display: block;
  }
}

.incomplete{
  color: $deep-red;
}

.complete{
  color: $light-green;
}

.upIcon {
  font-size: .8rem;
  margin-left: 5px;
}

.downIcon {
  font-size: .8rem;
  margin-left: 5px;
}

.siteAndShiftDate {
  font-size: 17px;
  color: $white;
  padding: .3em 1em;
}

.verticalLine {
  margin: 0;
  border-color: $black;
}

.emptyShiftAndSites {
  font-size: 14px;
  color: $white;
  padding: .3em 1em;
}

.siteAndShiftCard {
  color: $white;
  background-color: $selected-week-day-bg;
  margin: .5em 1em;
  border-radius: .5em;
  height: 3em;
  display: flex;
  flex-direction: row;
  padding-right: 1em;
  justify-content: space-between;
}

.siteAndShiftCardLeftContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.siteAndShiftLeftCard {
  height: 3em;
  width: 7em;
  border-radius: .5em;
  padding-left: .75em;
  font-weight: 500;
}

.siteAndShiftLeftCardHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25em;
  margin-top: 2px;
}

.siteAndShiftCardText {
  font-size: 11px;
}

.cardName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shiftAllocatedText {
  margin-top: .5em;
  color: $font-light-grey;
}

.shiftAllocatedNumbers {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lowNumber {
  color: $light-green;
}