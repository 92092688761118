@import '/src/global/colors.scss';

.toggle{
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	height: 48px;
	border: solid 2px $grey;
	background-color: $white;
	border-radius: 8px;
	width: 100%;
	color: $black;
	box-sizing: border-box;
	cursor: pointer;
	> i{
		font-size: 0.7em;
	}
	padding: 0 8px;

	&.disabled{
		pointer-events: none;
		* {
			pointer-events: none;
		}
	}
}

.selection{
	display: flex;
	width: 100%;
	align-items: center;
	gap: 8px;
	max-width: calc(100% - 22px);
	i{
		color: $light-green;
	}
	span {
		overflow: hidden;
		text-overflow: ellipsis;
		color: $black;
		white-space: nowrap;
		font-weight: 500;
	}
}

.options{
	display: flex;
	flex-direction: column;
	.option{
		background-color: $black;
		color: $white;
		cursor: pointer;
		height: 32px;
		padding: 0 32px;
		font-weight: 500;
		display: flex;
		align-items: center;
		justify-content: center;
		&:hover{
			background-color: $light-green;

		}
	}
}

.placeholder{
	color: $font-dark-grey;
	font-weight: normal;
	font-style: italic;
}