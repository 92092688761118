@import '../../global/colors.scss';

.lengthIndicator {
  font-size: 0.8rem;
  color: $white;
  padding-left: 1rem;
  margin: 0;

}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  width: 100%;
  textarea:focus~.lengthIndicator {
    color: $light-green;
  }
}