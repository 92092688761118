@import "../../global/colors.scss";

.inputContainer {
  display: flex;
  width: 100%;
  padding: 1rem 0rem;
  div {
    display: flex;
    flex-direction: column;
    flex: 5;
    margin: 0 1rem;
    .dateInputContainer {
      margin: 0;
      margin-top: 0.5rem;
      height: 2rem;
      border: 2.6px solid $border-bg-dropdown;
      border-radius: 10px;
      background-color: transparent;
      color: $white;
      flex-direction: row;
      padding: 0.5rem;
      input {
        background-color: transparent;
        border: none;
        flex: 1;
        color: $white;
      }
    }
  }
}

.calendarIcon {
  padding: 0.3rem;
  cursor: pointer;
}

.active {
  background-color: $light-green;
  border-radius: 5px;
}

.calendarContainer {
  position: absolute;
  background-color: $white;
  border-radius: 5px;
}

.shiftPatternInputContainer {
  div {
    span {
      color: $font-dark-grey;
      font-weight: bold;
    }
    .dateInputContainer {
      border: 2.6px solid $grey;
      background-color: transparent;
      
      input {
        background-color: transparent;
        color: $black;
        font-weight: bold;
      }
    }
  }
  .calendarIcon::before {
    color: $light-green;
  }
  .active::before {
    color: $white;
  }
}
