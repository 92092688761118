@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.inputPattern {
  display: flex;
  padding: 0.6rem;
  border: 1px solid $grey;
  background-color: $white;
  border-radius: 0.4rem;
  color: $font-deep-grey;
}

.shiftList {
  background-color: $white;
  padding: 0.6rem;
  overflow-y: auto;
  border-radius: 0.4rem;
}

.arrowIcon {
  color: $border-bg-dropdown;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
}

.patternCard {
  display: flex;
  margin-bottom: 0.5rem;
  background-color: $shift-pattern-light-grey-bg;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  align-items: center;
	border: solid 1px transparent;
  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    span {
      color: $shift-pattern-span-color;
      font-size: 0.8rem;
    }
    .name {
      color: $black;
      font-weight: bold;
      font-size: 1rem;
    }
    .daySpan {
      font-style: oblique;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: $row-hover-bg;
    border: 1px solid $light-green;
    .arrowIcon {
      color: $light-green;
    }
  }
}

.selectedPattern {
  background-color: $row-hover-bg;
  border: 1px solid $light-green;
  .arrowIcon {
    color: $light-green;
  }
}

.placeholder{
  font-style: italic;
  color: $font-deep-grey;
}