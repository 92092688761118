@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.header>button, .title>button {
  border: none;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: $white;
  cursor: pointer;
}

.header {
  display: flex;
  padding: 1rem;
  div {
    flex: 1;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    .icon {
      font-size: 1.5rem;
    }
  }
  .newReasonBtn {
    background-color: $light-green;
  }
  .saveBtn {
    background-color: $black;
  }
}

.switchContainer{
  display: flex;
  align-items: center;
  gap: 12px;
  >button{
    display: flex;
    align-items: center;
    background-color: transparent;
    i{
      font-size: 1.2em;
    }
  }
}

.body {
  background-color: $light-grey;
  height: calc(100% - 7rem);
  margin: 1rem;
  overflow-y: auto;
  border-radius: 0.5rem;
}

.card {
  display: flex;
  background-color: $white;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.6rem;
  border: 1px solid $grey;
  align-items: center;
  justify-content: space-between;
}

.inputForm {
  height: 2.7em;
  background-color: $white;
  border: 1px solid $font-light-grey;
  border-radius: 7px;
  outline: none;
  padding-left: 1.5em;
  width: 70%;
}

.deleteIcon {
  background-color: $black;
  color: $white;
  padding: 0.4rem 0.7rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
