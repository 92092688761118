@import "../../global/colors.scss";

.siteSchedules {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;

  >span {
    font-size: 0.7rem;
    color: $font-deep-grey;
  }
}

.scheduleIndicator {
  display: flex;
  align-items: center;
  gap: 0.1em;

  >i {
    color: $light-green;
  }

  >span {
    color: $border-bg-dropdown;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.scheduleList {
  display: flex;
  align-items: center;
  gap: 0.2em;

  >span {
    display: grid;
    place-items: center;
    font-size: 0.8rem;
    padding: 0.1em 0.4em;
    border-radius: 0.4em;
  }
}

.schedulePill {
  color: $font-deep-grey;
  background-color: fade-out($light-green, 0.7);
}

.overflown {
  background-color: $light-green;
  color: $white;
}
