@import "../../global/colors.scss";

.header {
  display: flex;
  align-items: center;
  .title {
    flex: 1;
    font-weight: bold;
  }
  .iconClose {
    background-color: $light-grey;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;
    border-radius: 0.2rem;
    cursor: pointer;
  }
}

.employeeList{
  overflow-x: hidden;
}

.applyPatternContainer{
  padding: 0.5em 0.5em 0;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.applyPatternContent{
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.modalContent{
  display: flex;
  height: 100%;
  width: 100%;
}

.leftContainer {
  padding-top: 0.4em;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.appliedPatternsContainer{
  width: 50%;
}

.employeeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 1em;

  .employee {
    display: flex;
    align-items: center;
    .usersIcon {
      color: $font-dark-grey;
      font-size: 1.5rem;
    }
  }
  .employeeText {
    margin-left: 0.5rem;
    color: $font-dark-grey;
    font-weight: bold;
  }
  input{
    cursor: pointer;
  }
}

.selectedEmployeeContainer {
  display: flex;
  align-items: center;
  .usersIcon {
    color: $font-dark-grey;
    font-weight: bold;
    font-size: 1.5rem;
  }
  .text {
    color: $black;
    font-weight: bold;
    margin-left: 0.5rem;
  }
}

.employees {
  background-color: $light-grey;
   border-radius: 9px;
  overflow-y: auto;
  padding: 0.4rem;
  display: flex;
  flex-flow: wrap;
  height: 100%;
  >div{
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    align-items: center;
    height: min-content;
  }
  span {
    background-color: orange;
    padding: 0.3rem 1rem;
    box-sizing: border-box;
    border-radius: 9px;
    color: white;
    display: flex;
    align-items: center;
  }
}

.buttonContainer {
  display: flex;
  justify-content: center;
  .applyPatternBtn {
    background-color: $light-green;
    color: $white;
    border: none;
    padding: 1rem 5rem;
    border-radius: 0.3rem;
    font-weight: bold;
    cursor: pointer;
  }
  >button:disabled{
    background-color: fade-out($light-green, 0.6);
    cursor: default;
  }
}
