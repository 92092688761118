@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.container {
  width: 100vw;
  height: 100vh;
}

.errorContainer {
  height: calc(100% - 60px);
  padding-top: 60px;
  background-color: $grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  grid-template-rows: 1fr;
}

.errorIcon{
  color: $red;
  font-size: 6rem;
}

.errorImage{
  width: 10em;
}

.errorText{
  font-size: 2rem;
  color: $font-deep-grey;
  font-weight: bold;
}

.retryButton{
  font-size: 1rem;
  color: $white;
  border: none;
  border-radius: 7px;
  background-color: $light-green;
  padding: 0.5rem 1.3rem;
  font-family: 'Poppins';
  font-weight: 400;
  cursor: pointer;
}