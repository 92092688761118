@import "../../global/colors.scss";

.iconArrow::before {
  font-size: 0.7rem;
  color: $white;
}

.icon::before {
  color: $white;
}

.permissionsContainer {
  .permission {
    display: flex;
    align-items: center;
    background-color: $bg-modal;
    padding: 0.5rem 1rem;
    margin-top: 0.8rem;
    cursor: pointer;
  }
  .title {
    color: $white;
    margin: 0 0.5rem;
    font-weight: 100;
    font-size: 1rem;
  }
}

.titleContainer {
  display: flex;
  flex: 1;
  align-items: center;
}

.addVacationContainer {
  background-color: $light-green;
  height: 2rem;
  border-radius: 5px;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  border: 1px solid $light-green;
  border-bottom: none;
}
