@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.publishBanner {
  background-color: $deep-faded-green;
  display: flex;
  align-items: center;
  position: fixed;
  top: 60px;
  z-index: 20;
  border-top: solid 1px $light-green;
  width: 100%;
  color: $white;
  height: 30px;
  justify-content: center;
  gap: 10px;
  box-sizing: border-box;
  >span {
    font-size: 0.85em;
  }

  >i {
    color: $light-green;
    -webkit-animation: rotate-center 2s linear infinite both;
    animation: rotate-center 2s linear infinite both;
  }
}


@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.headerBody {
  height: 60px;
  background-color: $dark-black;
  display: flex;
  align-items: center;
  color: $white;
  z-index: 4;
  position: fixed;
  top: 0;
  width: 100%;

  @media #{$tablet-up} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $black;
  }
}

.highlightText {
  color: $light-green;
}

.dropdown {
  margin-right: 1em;
  flex: 1;
}

.headerIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  span {
    padding: 8px;

    &::before {
      font-size: 1.5em;
    }
  }

  @media #{$tablet-up} {
    flex: 0;
  }
}

.logo {
  display: none;

  @media #{$tablet-up} {
    display: inline-flex;
  }
}

.mainTitle {
  display: none;

  @media #{$tablet-up} {
    display: flex;
    flex: 1 1;
    font-size: 1.2em;
    margin-left: 5px;
    font-weight: bold;
  }
}

.state {
  display: none;
  padding-right: 1em;

  span::before {
    color: $light-green;
  }

  @media #{$tablet-up} {
    display: block;
  }
}

.sateTitle {
  font-weight: 100;
}

.stateInfo {
  font-weight: 500;
}

.publishBtn {
  display: none;
  padding: 10px;
  background-color: $light-green;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 1em;
  color: white;
  cursor: pointer;

  span {
    padding-right: 5px;

    &::before {
      font-size: 1em;
    }
  }

  @media #{$tablet-up} {
    display: block;
  }

  &.publish {
    background-color: $blue;
  }

  &:disabled{
    opacity: 0.5;
    cursor: default;
  }
}

.userNameContainer {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-weight: 400;

  .userIconContainer {
    background-color: $filter-input-hover-bg;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border-radius: 0.3rem;
  }

  @media #{$tablet-up} {
    margin-right: 2rem;
  }
}