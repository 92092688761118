@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.commentsButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .25em;
    padding-left: 1em;
}

.iconButtonContianer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.iconButtonContainer{
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.badge{
  color: $deep-red;
  font-size: 0.4em;
}

.emptyComments {
    padding: 1em;
    color: $white;
    font-size: 15px;
}

.addCommentsTitleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5em;
    color: $white;
    font-size: 18px;
    font-weight: bold;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.addCommentText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5em;
}

.verticalLine {
    margin: 0;
    border-color: $black;
}

.addCommentsContainer {
    display: flex;
    flex-direction: column;
    padding: 1em 1em;
    color: $white;
    gap: 1em;
}

.comments {
    background-color: $comment-bg;
    padding: 1em;
    font-size: 11px;
    border-radius: 8px;
    margin-top: .5em;
}

.commentsHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: .5em;
    font-size: 15px;
}

.buttonContainer {
    background-color: $bg-modal;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.iconClose::before,
.iconDelete::before {
    color: $white;
}

.commentInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    color: $light-green;
}

.textArea {
    background-color: $dark-grey-bg;
    color: $white;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 1em;
}

.textArea::placeholder {
    padding: .25em;
    color: $font-dark-grey;
}

.saveButton {
    display: none;
    padding: 10px;
    background-color: $light-green;
    border: 1px solid $light-green;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 1em;
    color: $white;
    cursor: pointer;

    span {
        padding-right: 5px;

        &::before {
            font-size: 1em;
        }
    }

    @media #{$tablet-up} {
        display: block;
    }

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}