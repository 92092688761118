@import "../../global/colors.scss";

.dateContainer {
  display: flex;
  margin-top: 12px;
  color: $white;
  flex-direction: column;
  background-color: $bg-modal;
  padding: 5x;
  border-radius: 10px;
  div {
    text-align: -webkit-center;
  }  
}

.dateContainerInfo {
  display: flex;
  width: 90%;
  justify-content: space-around;
  margin-top: 10px;
  align-self: center;
}

.dayContainer {
  background-color: $black;
  border-radius: 5px;
  cursor: pointer;
  height: 2.5rem;
  width: 2.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: bold;
}

.dayName {
  text-transform: uppercase;
  font-size: 0.8em;
}

.dayContainerDisabled {
  background-color: $border-bg-dropdown;
  color: $bg-modal;
  border-radius: 5px;
  cursor: default;
}

.dropdownContainer {
  margin: 15px;
  margin-top: 0;
}

.dateContainerTitle {
  margin-left: 1.5em;
  margin-top: 10px;
  color: $font-light-grey;
  font-weight: bold;
}

.selectedDay {
  background-color: $light-green;
}