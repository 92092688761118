@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.weekDaysContainer {
  display: none;

  @media #{$tablet-up} {
    display: grid;
    grid-gap: 0;
    width: 100%;
    border-bottom: 1px solid $grey;
  }
}

.disabled{
	position: relative;
	background-color: $clear-grey !important;
	pointer-events: none;		
	* {
		opacity: 0.9;
		pointer-events: none;		
	}
}

.borderBottom {
  border-bottom: solid 1px $grey;
}

.greyBackground {
  background-color: $light-grey;
}

.emptySlot {
  text-align: center;
  border-right: 1px solid $grey;
}

.divisorLine {
  border-left: solid 1px $divisor-line;
}

.slot {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  border-right: 1px solid $grey;
}

.iconOptions::before {
  color: $medium-grey;
}

.iconOptions {
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
}

.leaveContainer {
  display: flex;
  flex-direction: column;
}

.leaveSlot {
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid $grey;
  justify-content: center;
  cursor: default;
  color: $font-light-grey;
}

.leaveTitle {
  font-size: 0.8rem;
}

.transferContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: default;
  color: $light-green;
  .transferContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .iconRelocated {
      font-size: 1.3rem;
      // margin-right: 0.3rem;
    }
    .label {
      font-size: 0.7rem;
    }
  }
}
