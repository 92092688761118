@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.body {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $light-grey;
  overflow: hidden;
}

.containers {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100vh - 64px);
  gap: 1.5em;
  position: fixed;
  top: 64px;
  padding: 1em;
  box-sizing: border-box;
}

.leftContainer {
  background-color: $white;
  width: 35%;
  border-radius: 10px;
  box-shadow: 0 2px 4px $font-dark-grey;
  overflow-y: auto;
}

.rightContainer {
  background-color: $white;
  width: 65%;
  border-radius: 10px;
  box-shadow: 0 2px 4px $font-dark-grey;
  display: flex;
}
