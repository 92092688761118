@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.viewInfoBtn {
  background-color: $black;
  color: $white;
  height: 2rem;
  width: 6rem;
  display: flex;
  align-items: center;
  gap: 0.5em;
  border: none;
  border-radius: 0.3rem;
  justify-content: space-evenly;
  align-self: center;
  margin-left: 0.5rem;
  cursor: pointer;
}

.buttonLabel{
  white-space: nowrap;
}

.placeholder{
  width: 100%;
  display: grid;
  place-items: center;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 20px;
  color: $font-dark-grey;
  text-align: center  ;
}

.dialogContent{
  width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 0.4em;
}

.title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 0.94em;
  font-weight: bold;
  gap: 1em;
}

.closeIcon{
  color: $font-dark-grey;
  cursor: pointer;
}

.userList{
  min-height: 20px;
  height: min-content;
  max-height: 400px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.userList.searchEnabled{
  height: 400px;
}

.avatar{
  height: 2em;
  min-height: 2em;
  max-height: 2em;
  width: 2em;
  min-width: 2em;
  max-width: 2em;
  display: grid;
  place-items: center;
  border-radius: 0.3em;
}

.user{
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.2em 0;
  width: 100%;
}

.shortName{
  font-size: 0.9em;
}