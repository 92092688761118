@import "../../global/colors.scss";

.headerContainer {
  display: flex;
  align-items: center;
  .headerTitle {
    color: $white;
    font-weight: bold;
    flex: 1;
  }
  .doneBtn {
    background-color: $light-green;
    color: $white;
    border: none;
    border-radius: 5px;
    height: 2.5rem;
    cursor: pointer;
    width: 6rem;
    margin-right: 0.5rem;
  }

  .closeModal {
    background-color: $bg-modal;
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

.icon::before {
  color: $white;
}

.permissionIcon::before {
  color: $white;
  font-size: 1.4rem;
  margin-right: 0.5rem;
}