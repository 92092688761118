@import "../../global/colors.scss";

.container {
  border: 1px solid $light-green;
  border-top: none;
  padding: 1rem;
  color: $white;
}

.header {
  display: flex;
  align-items: center;
  span {
    flex: 1;
  }
  .saveBtn {
    background-color: $light-green;
    border: none;
    color: $white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

.inputContainer {
  display: flex;
  width: 100%;
}

.calendarIcon {
  padding: 0.3rem;
  cursor: pointer;
}

.active {
  background-color: $light-green;
  border-radius: 5px;
}

.closeBtn {
  background-color: $bg-modal;
  border: none;
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.iconClose::before {
  color: $white;
}

.calendarContainer {
  position: absolute;
  top: -82px;
  background-color: $white;
  border-radius: 5px;
}