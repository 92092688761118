@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.UserCalendarContainer {
  display: none;
  width: 100%;
  z-index: 3;
  @media #{$tablet-up} {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 109px;
    &.pendingWeek{
      top: 139px
    }
  }
  @media (max-width: 1260px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 109px;
    &.pendingWeek{
      top: 139px
    }
  }

  @media #{$mobile-query} {
    display: none;
  }
}

.week {
  display: flex;
}

.userSearchContainer {
  background-color: $black;
  height: 40px;
  color: white;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  width: 34vw;
}

.userCountInfo {
  flex: 1;
  input {
    display: flex;
    padding: 0.5rem;
    border: none;
    width: 90%;
    background-color: $bg-modal;
    border-radius: 10px;
    color: $white;
    &:focus {
      outline: none;
      border: none;
    }
  }
}

.usersTitle {
  padding: 0px 10px;
}

.usersCount {
  font-size: 1.2em;
  font-weight: bold;
}

.filterContainer,
.searchIconContainer {
  cursor: pointer;
  width: 1em;
  background-color: $white;
  padding: 5px;
  display: flex;
  height: 1em;
  border-radius: 5px;
  justify-content: center;
  span {
    display: flex;
    align-self: center;
  }
}

.filterContainer {
  background-color: $light-green;
  margin-left: 5px;
}

.closeContainer {
  cursor: pointer;
  background-color: transparent;
  width: 1em;
  padding: 5px;
  display: flex;
  height: 1em;
  border-radius: 5px;
  justify-content: center;
}

.iconClose {
  &::before {
    color: $white;
  }
}

.iconSearch:before {
  color: $black;
  font-size: small;
}

.registers {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0;
  width: 100%;
}

.emptySlot {
  color: $font-dark-grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: $white;
  place-content: center;
}

.isToday {
  background-color: $light-green;
  color: $white;
}

.weekDaysNumIsToday {
  font-size: 1.3em;
  font-weight: bold;
  color: $white;
  margin-left: 2px;
}

.weekDaysNum {
  font-size: 1.3em;
  font-weight: bold;
  color: $font-dark-grey;
  margin-left: 2px;
}