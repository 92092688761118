@import "../../global/colors.scss";

.container {
  border: 1px solid $light-green;
  border-top: none;
  padding: 1rem;
  color: $white;
}

.header {
  display: flex;
  align-items: center;
  span {
    flex: 1;
  }
  .saveBtn {
    background-color: $light-green;
    border: none;
    color: $white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
}

.inputContainer {
  display: grid;
  grid-template-columns: auto min-content;
  width: 100%;
  gap: 1em;
  .containers {
    display: flex;
    flex-direction: column;
  }
}

.input {
  margin: 0;
  height: 2rem;
  border: 2.6px solid $border-bg-dropdown;
  border-radius: 5px;
  background-color: transparent;
  color: $white;
  padding-left: 1rem;
}

.closeBtn {
  background-color: $bg-modal;
  border: none;
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
}

.iconClose::before {
  color: $white;
}

.explanationContainer {
  display: flex;
  flex-direction: column;
}

.dropdownTitle{
  font-size: 1rem;
  font-weight: bold;
  color: $font-light-grey;
  padding: 0 1rem;
}

.dateInputContainer {
  margin: 0;
  border: 2.6px solid $border-bg-dropdown;
  border-radius: 5px;
  background-color: transparent;
  color: $white;
  display: flex;
  flex-direction: row;
  padding: 0.4rem;
  input {
    background-color: transparent;
    border: none;
    flex: 1;
    color: $white;
  }
}

.calendarIcon {
  padding: 0.3rem;
  cursor: pointer;
}

.active {
  background-color: $light-green;
  border-radius: 5px;
}