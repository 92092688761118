@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.headerBody {
  height: 60px;
  background-color: $dark-black;
  display: flex;
  align-items: center;
  color: $white;
  z-index: 6;
  align-content: center;
  justify-content: space-between;
  @media #{$tablet-up} {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: $black;
    width: 100%;
    // display: grid;
    // grid-template-columns: 1fr auto;
  }
}

.headerIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding: 8px;
    &::before {
      font-size: 1.5em;
    }
  }
  .headerTitle {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.dropdownContainer {
  @media #{$tablet-up} {
    display: grid;
    grid-template-columns: 2fr 2fr repeat(6, 1fr);
    grid-gap: 0.5rem;
    align-items: center;
    width: calc(100% - 277px);
  }
}

.inputContainer {
  display: flex;
  flex: 1;
  margin: 0;
  height: 2rem;
  border-radius: 0.3rem;
  background-color: $bg-modal;
  flex-direction: row;
  padding: 0.2rem;
  align-items: center;
  input {
    display: flex;
    font-family: 'Poppins';
    padding: 0.5rem;
    border: none;
    width: 90%;
    background-color: transparent;
    border-radius: 10px;
    color: $white;
    &::placeholder{
      color: $font-light-grey;
    }
    &:focus {
      outline: none;
      border: none;
    }
  }
  .searchIcon {
    padding-left: 0.5rem;
  }
}

.filterContainer {
  background-color: $bg-modal;
  color: $white;
  height: 2rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  cursor: pointer;
  span{
    margin-left: 0.5rem;
  }
  .text {
    font-size: 1.1rem;
    font-weight: 500;
    color: $font-dark-grey;
    max-height: 2rem;
    white-space: nowrap;
  }
}

.monthBtn {
  cursor: pointer;
  border: none;
  padding: 0.6rem;
  border-radius: 0.3rem;
  color: $white;
  background-color: $light-green;
  max-height: 2rem;
  white-space: nowrap;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.todayBtn {
  cursor: pointer;
  border: none;
  padding: 0.6rem;
  border-radius: 0.3rem;
  width: min-content;
  color: $white;
  background-color: $light-green;
  max-height: 2rem;
  white-space: nowrap;
}