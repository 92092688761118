@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.mainBarBody {
  flex-direction: column;
  width: 100%;
  height: 140px;
  align-items: normal;
  background-color: $dark-black;
  color: $white;
  display: flex;
  position: fixed;
  top: 60px;
  z-index: 3;

  &.pendingWeek{
    top: 90px
  }
  @media #{$tablet-up} {
    left: 0;
    right: 0;
    height: 50px;
    align-items: center;
    flex-direction: row;
  }
}

.todayBtn{
    display: none;
    padding: 10px;
    background-color: $light-green;
    border-radius: 5px;
    font-size: 0.85em;
    color: $white;
    cursor: pointer;
    font-family: 'Poppins';
  
    @media #{$tablet-up} {
      display: block;
    }
}

.dropdownContainer {
  display: flex;
  width: 100%;
  flex: 0;
  padding: 10px 0px;
  padding-top: 3px;

  @media #{$tablet-up} {
    flex: 3;
    padding: 0;
  }
}

.infoContainer {
  display: flex;
  flex: 0;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  justify-content: end;
  
  @media #{$tablet-up} {
    flex: 8;
    padding: 0;
  }
}

.aircraftType {
  margin-left: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.bullet{
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 0.3em;
}

.aircraftName{
  font-size: 0.7em;
}

.aircraftContainer {
  display: flex;
  flex: 1;
  @media #{$mobile-query} {
    display: none;
  }
}

