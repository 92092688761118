@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.window{
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.roleManagement{
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
  padding: 23px;
  max-height: calc(100% - 60px - 46px);
  height: 100%;
  gap: 15px;
  background-color: $light-grey;
  overflow-y: auto;
}

.folders{
 flex: 1 0 calc(33.3% - 48px);
 max-height: 100%;
 min-width: 410px;
 border-radius: 10px;
 background-color: $white;
 display: flex;
 flex-direction: column;
 box-shadow: 0 2px 3px 2px $grey;
 overflow-y: hidden;
 @media (max-width: calc(1275px + 46px + 30px)) {
  max-height: calc(50% - 12px);
 }
}



.newFolderImage{
  width: 23px;
}


.role{
  flex: 1 0 calc(33.3% - 48px);
  max-height: 100%;
  min-width: 479px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 2px 3px 2px $grey;
  overflow-y: hidden ;
  @media (max-width: calc(1275px + 46px + 30px)) {
    max-height: calc(50% - 12px);
  }
}

.actions{
  flex: 1 0 calc(33.3% - 48px);
  max-height: 100%;
  min-width: 380px;
  border-radius: 10px;
  background-color: $white;
  box-shadow: 0 2px 3px 2px $grey;
  height: 100%;
  @media (max-width: calc(1275px + 46px + 30px)) {
   max-height: calc(50% - 12px);
  }
}

.folder{
  padding: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
}

.rolesContainer{
  padding-left: 43px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.folderContainer{
  display: flex;
  flex-direction: column;
}
