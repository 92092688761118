@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.formSelect {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
}

.searchBar {
  margin: 0.5em 1em;
  position: sticky;
  top: 0.5em;
  background-color: $white;
  border-radius: 7px;
  box-shadow: 0px 0px 4px 4px $white;
}

.list {
  display: flex;
  flex-direction: column;
  padding: 0.5em 0;
  min-width: 400px;
  > span {
    font-size: 0.9rem;
    padding: 0.5em 1em;
    cursor: pointer;
    &:hover {
      background-color: $row-hover-bg;
    }
  }
}

.inputContainer {
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  &::after {
    content: "⏷";
    position: absolute;
    right: 0.7em;
    color: $font-deep-grey;
    pointer-events: none;
  }
  > input {
    width: 100%;
    height: 3em;
    background-color: $white;
    border: 1px solid $font-light-grey;
    border-radius: 7px;
    outline: none;
    padding: 0 2em 0 1.5em;
    font-family: "poppins";
    cursor: default;
    @media (orientation: portrait) and (min-width: $mobile) {
      margin-top: 10px;
    }
  }
  > .selection {
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    padding: 0 2em 0 1.5em;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    >div{
      width: 100%;
      white-space: nowrap;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.enabledSelect{
  cursor: pointer;
  >input{
    cursor: pointer;
  }
  &:hover > input {
    border: solid 1px $black;
  }
}

.italic {
  font-style: italic;
  font-weight: 300;
}
