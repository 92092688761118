@import "../../global/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow: auto;
  max-height: calc(100vh - 15em);
}

.buttonsBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}

.placeholder {
  font-style: italic;
  width: 100%;
  display: grid;
  place-items: center;
  color: $font-dark-grey;
}

.saveButton {
  border: none;
  background-color: $light-green;
  border-radius: 6px;
  color: $white;
  min-height: 3em;
  font-weight: bold;
  padding: 0 3em;
  cursor: pointer;
}

.closeButton {
  color: $font-deep-grey;
  border: none;
  background-color: $light-grey;
  border-radius: 6px;
  aspect-ratio: 1/1;
  min-height: 3em;
  cursor: pointer;
  font-weight: bold;
}

.card {
  font-weight: bold;
  background-color: $light-grey;
  border-radius: 9px;
  padding: 0.6em 1em;
  transition: 0.4s;
}

.card.enabled {
  background-color: fade-out($light-green, 0.85);
  transition: 0.4s;
}

.patternList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  gap: 0.7em;
}

.patternTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  >i{
    cursor: pointer;
  }
}

.pattern {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  padding-bottom: 1em;
}

.patternContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.patternInfo {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  max-width: 75%;
  width: 75%;
}

.datesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.dateInfo {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.dateTitle {
  font-weight: normal;
  color: $font-deep-grey;
  transition: 0.4s;
}

.dateTitle.enabled {
  color: $light-green;
  transition: 0.4s;
}

.date {
}

.userCard {
  padding: 0.4em 1em;
  border-radius: 6px;
  color: $white;
  font-weight: normal;
  font-size: 0.85em;
  background-color: $light-green;
}

.switchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;
}

.switchLabel {
  font-size: 0.85em;
}

.userCard.usersOverflow {
  background-color: $light-green;
  padding: 0.4em 0.5em;
}
