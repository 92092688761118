@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.dateContainerInfo {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-self: center;
  padding: 15px 0px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  @media #{$tablet-up} {
    display: none;
  }
}

.dayName {
  text-transform: uppercase;
  font-size: 0.8em;
  color: $font-dark-grey;
}

.dayContainer {
  background-color: $white;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.1em;
  font-weight: 400;
  padding: 5px;
}

.userRegisterContainer {
  span {
    font-size: 0.8rem;
  }
  i {
    margin-right: 3px;
    &::before {
      font-size: 0.7rem;
    }
  }
}

.iconRelocated {
  color: $light-green;
}