@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.filterContainer {
  background-color: $dark-black;
  position: absolute;
  top: 30px;
  left: 25vw;
  z-index: 20;
  width: 30%;
  border-radius: 11px;
  overflow-y: auto;
  max-height: 60vh;
}

.title {
  display: flex;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid $font-dark-grey;
  .titleText {
    font-weight: bold;
    font-size: 1.2rem;
    flex: 1;
  }
  .filterIcon {
    height: 100%;
    display: flex;
    margin-right: 0.8rem;
    &::before {
      color: $light-green;
      font-size: 1.2rem;
    }
  }
  button {
    background-color: $light-green;
    color: $white;
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
  }

  button.clearButton{
    border-radius: 1.5rem;
    background-color: fade-out($light-green, 0.7);
    padding: 0.5rem 1rem;
    color: $light-green;
  }
  .closeFilterIcon {
    margin-left: 0.5rem;
    color: $font-dark-grey;
    font-size: small;
    cursor: pointer;
  }
}

.buttonsContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
}

.filterCategory {
  align-items: center;
  border-bottom: 1px solid $font-dark-grey;
  padding: 1rem;
  cursor: pointer;
}

.filterCategoryHeader {
  display: flex;
  align-items: center;
  width: 100%;
  .CategoryName {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
}

.showLess {
  background-color: $bg-modal;
  padding: 0 0.6rem;
  color: $light-green;
  border-radius: 5px;
}

.openCategory {
  display: block;
  padding: 1rem 0 0 0;
}

.closedCategory {
  display: flex;
}

.openCatHeader {
  padding: 0 1rem 1rem 1rem;
  width: auto;
}

.filterCategoryBody {
  border-top: 1px solid $font-dark-grey;
  padding: 1rem;
  list-style: none;
  ul {
    list-style: none;
    li {
      margin-top: 0.5rem;
      &:hover {
        border-radius: 5px;
        background-color: $filter-input-hover-bg;
        border: 1px solid $light-green;
        cursor: pointer;
      }
    }
  }
}

.isItemSelected {
  background-color: $filter-input-hover-bg;
  padding: 0.4rem 0.2rem;
  border: 1px solid $light-green;
  border-radius: 5px;
}

.container {
  display: flex;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: 1px solid $font-dark-grey;
  border-radius: 5px;
}


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $light-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.container{
  >.filterName{
    min-height: 1em;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $scroll-bar-bg;
  border-radius: 10px;
}

.monthView {
  top: 60px;
  left: 40vw;
}