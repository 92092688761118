@import "../../global/colors.scss";
@import "../../global/media_query_variables.scss";

.container {
  height: calc(100vh - 60px);
  margin-top: 60px;
  width: 100vw;
  display: flex;
  padding: 20px;
  gap: 18px;
  box-sizing: border-box;
  background-color: $light-grey;
}

.crewsContainer {
  width: 33%
}

.crewsContainerHeader {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 17px;
}

.employeeListHeader{
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0 0 13px 0;
}

.employeeName{
  font-size: 0.95em;
  line-height: 1;
}

.employeeCode{
  color: $font-dark-grey;
  font-size: 0.85em;
  line-height: 1;
}

.employeeAdditionalInfo{
  display: flex;
}

.nameContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
}

.crewList {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0 0 1em 0 ;
  gap: 8px;
}


.employeeIcon {
  font-size: 1.8em;
}

.employeesHeader {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 20px 30px;
  box-sizing: border-box;
  align-items: center;
}

.headerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.employeeTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: $font-dark-grey;
  line-height: 1;
}

.employeeDescription {
  font-size: 0.83em;
  line-height: 1;
  color: $font-dark-grey;
}

.employeesContainer {
  width: 67%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.deleteIcon {
  color: $white;
  display: grid;
  place-items: center;
  margin-top: 3px;
}

.headerButton.highlightButton{
  background-color:  $light-green;
  transition: .4s;
}

.headerButtons {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
}

.employeesBody {
  display: flex;
  gap: 25px;
  padding: 0 30px 30px 30px;
  height: 100%;
  overflow: hidden;
}

.selectedEmployeesIcon{
  margin-top: 4px;
  font-size: 1.6em;
}

.employeeListContainer {
  width: 47%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.employeeList {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: solid 1px $grey;
  overflow: auto;
}

.first-emlpoyee {
  border-top: solid 1px $grey;
}

.dragIcon{
  color: $medium-grey;
  display: grid;
  place-items: center;
}

.dropdown{
  flex: 0 1;
  padding: 17px 17px 0 17px;
}

.employeeCard {
  border-bottom: solid 1px $grey;
  // background-color: fade-out($light-green, 0.86);
  display: flex;
  align-items: center; 
  padding: 8px 10px 8px 7px;
  cursor: grab;
  // transition: .12s;
}

.crew.selectedCrew{
  background-color: fade-out($light-green, 0.86);
  border: solid 1px $light-green;
}

.employeeChip{
  // color: $white;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 7px;
  gap: 10px;
  font-size: 0.9em;
}

.employeeInfo{
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
}

.employeeCrew{
  // color: $light-green;
  font-weight: bold;
  color: $font-dark-grey;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  > span{
    white-space: nowrap;
  }
  >.crewSite{
    font-weight: normal;
    font-size: 0.7em;
  }
}

.employeeSquare{
  // mix-blend-mode: difference;
  font-size: 1.2em;
  // background-color: $orange;
  border-radius: 9px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  // color: $white;
  display: grid;
  place-items: center;
}

.highlightEmployee{
  background-color: fade-out($light-green, 0.86);
  // transition: .12s;
}

.crewHighlight{
  color: $light-green;
}

.employeeAvatar{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  justify-content: center;
  min-width: 56px;
}

.skillCirlce{
  display: grid;
  place-items: center;
}

.skills{
  font-size: 0.57em;
  display: flex;
  align-items: center;
  gap: 1px;
}


.selectedEmployees {
  width: 53%;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.membersBackground {
  background-color: $light-grey;
  border-radius: 20px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.members {
  box-sizing: border-box;
  padding: 15px;
  border: dashed $font-deep-grey 1px;
  width: calc(100% - 25px);
  height: calc(100% - 25px);
  border-radius: 10px;
}

.removeUser{
  font-size: 0.9em;
  margin-top: 3px;
  cursor: pointer;
}

.memberContent{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  max-height: 100%;
  overflow: auto;
}

.selectedHeader {
  display: flex;
  align-items: center;
  color: $font-light-grey;
  gap: 10px;
}

.overFLowList {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.userCard {
  font-size: 0.7em;
  padding: 0.4em 0.8em;
  background-color: $black;
  width: min-content;
  border-radius: 7px;
  color: $white;
  white-space: nowrap;
}

.employeeCard:active{
  filter: none;
}

.card {
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 3px 2px $grey;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}

.customInput {
  font-size: 0.9rem;
  padding: 8px 34px 8px 15px;
  border-radius: 7px;
  font-family: "Poppins";
  width: calc(100% - 50px);
  outline: none;
  border: solid 1px $font-light-grey;
}

.headerButton {
  background-color: $black;
  color: white;
  border-radius: 7px;
  border: none;
  font-size: 0.9rem;
  padding: 10px 18px;
  font-family: "Poppins";
  white-space: nowrap;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: min-content;
  gap: 7px;
  width: 50px;
}
