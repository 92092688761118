@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.skillsContainer {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1em;
  position: relative;
  // width: 100%;
  &.width100{
    width: 100%;
  }
  .badgeLabel{
    font-size: 0.7em;
    color: $font-dark-grey;
  }
}

.badgeList {
  display: flex;
  flex-direction: row;
  gap: 0.1em;
  align-items: center;
  justify-content: center;
  &.squareList{
    gap: 0.2em;
  }
}
.squareBadge {
  font-size: 0.8rem;
}

.endorsements{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  font-size: 0.8em;
  gap: 0.2em;
  margin-left: 0.2em;
  padding: 0.5rem 0;
  >span{
    border-radius: 1rem;
    background-color: fade-out($light-green, 0.7);
    padding: 0.2rem 0.4rem;
    color: $light-green;
    &.license{
      color: $blue;
      background-color: fade-out($blue, 0.7);
    }
  }
}

.detailedBadges {
  font-size: 1rem;
  color: $white;
  display: flex;
  flex-direction: column;
}

.skill{
  border-bottom: solid 1px $border-bg-dropdown;
  border-right: solid 1px $border-bg-dropdown;
  border-left: solid 1px $border-bg-dropdown;
  background-color: $dark-menu;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6em;
  padding: 0 0.5em 0 0.9em;
  min-height: 2.5rem;
  &:first-child{
    border-radius: 7px 7px 0 0;
    border: solid 1px $border-bg-dropdown;
  }
  &:last-child{
    border-radius: 0 0 7px 7px;
  } 

}

.skillBadge{
  font-size: 0.5rem;
}

.skillName{
  font-style: italic;
  font-weight: bold;
  font-size: 0.9em;
  white-space: nowrap;
}

.plane{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4em;
}

.circleBadge{
  font-size: 0.5rem;
}

.circlesList{
  gap: 0.25rem
}

.additionalCircleBadges{
  color:$font-dark-grey;
  font-weight: bold;
  font-size: 0.5rem;
}

.additionalSquareBadges{
  color: $font-dark-grey;
  font-weight: bold;
  font-size: 0.8rem;
}