@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.header {
  display: flex;
  align-items: center;

  .title {
    flex: 1;
    font-weight: bold;
    display: flex;
    color: $border-bg-dropdown;

    .iconLocation {
      font-size: 1.5rem;
    }
  }
}

.sitesHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  gap: 1em;
  padding: 0 1em;
  box-sizing: border-box;
}

.sitesHeaderButtons {
  display: flex;
  align-items: center;
  gap: 1em;
}

.sitesTitle {
  display: flex;
  align-items: center;
  gap: 0.5em;

  >label {
    color: $border-bg-dropdown;
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.sites {
  padding: 0.9em 0 0 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  gap: 0.8em;
}

.newLocationForm {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
  padding: 1em;
  width: 100%;
}

.requiredMarker {
  color: $deep-red;
}

.formBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: $white;
  font-weight: bold;
  background-color: $black;
  width: min-content;
  white-space: nowrap;
  padding: 0 1.5em;
  height: 3em;
  border-radius: 7px;
  cursor: pointer;

  &.saveBtn {
    padding: 0 3.2em;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

}

.formHeader {
  box-sizing: border-box;
  padding: 0 1em;
  display: grid;
  grid-template-columns: 9rem auto 17rem;
  gap: 1rem;
}

.cityContainer {
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
}

.formGroup {
  display: flex;
  flex-direction: column;

  label {
    color: $border-bg-dropdown;
    font-weight: bold;
    font-size: 0.9rem;
    padding-left: 1rem;
  }
}

.inputForm {
  font-size: 1rem;
  height: 2.5em;
  font-weight: bold;
  padding: 0 1em;
  background-color: transparent;
  border: 1px solid $font-light-grey;
  border-radius: 5px;
  outline: none;
  color: $black;
}


.siteContainer {
  background-color: $light-grey;
  height: 100%;
  border: 1px solid $grey;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
}

.iconSite {
  font-size: 1.3rem;
}

.siteTitle {
  font-weight: bold;
  color: $border-bg-dropdown;
}

.deleteIcon {
  background-color: $black;
  color: $white;
  padding: 0.4rem 0.7rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &[class*="highlightButton"] {
    background-color: $light-green;
  }
}

.siteCard {
  display: flex;
  background-color: $white;
  padding: 0.5em 1em;
  gap: 0.5em;
  border-radius: 0.6rem;
  border: 1px solid $grey;
  align-items: center;
  justify-content: space-around;
  transition: all 0.3s ease;

  >label {
    color: $border-bg-dropdown;
    font-weight: bold;
    font-size: 0.9rem;
  }

  &.highlightSite {
    transition: all 0.3s ease;
    border-color: $light-green;
    background-color: fade-out($light-green, 0.93);

    input {
      border-color: $light-green;
    }
  }
}

.siteCode {
  width: 3em;
}

.siteName {
  width: 5em;
}

.editingText {
  background-color: $white;
  color: $light-green;
  font-style: italic;
}

.blinkEffect {
  animation: blinkAnimation 0.9s infinite;
}

@keyframes blinkAnimation {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: $row-hover-bg;
  }

  100% {
    background-color: transparent;
  }
}