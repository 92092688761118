@import "./global/media_query_variables.scss";
@import "./global/colors.scss";

.headerMainContainer {
  width: 100%;
}

.headerTopNoSummary {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 159px;
    margin-bottom: 50px;
    &.pendingWeek{
      margin-top: calc(159px + 30px);
    }
  }
  @media(max-width: 1260px) and (min-width: 576px) {
    margin-top: 159px;
    margin-bottom: 50px;
    &.pendingWeek{
      margin-top: calc(159px + 30px);
    }
  }
}

.headerTop {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 191px;
    margin-bottom: 50px;
    &.pendingWeek{
      margin-top: calc(191px + 30px);
    }
  }
  @media (max-width: 1260px) and (min-width: 576px) {
    margin-top: 206px;
    margin-bottom: 50px;
    &.pendingWeek{
      margin-top: calc(206px + 30px);
    }
  }
}

.headerTopNoMarginBottom {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 110px;
    margin-bottom: 0;
  }
}

button{
  font-family: 'Poppins';
  border-style: none;
  &.transparent{
    border-radius: 1.5rem;
    background-color: fade-out($light-green, 0.7);
    padding: 0.5rem 1rem;
    color: $light-green;
    white-space: nowrap;
  }
}

pre{
  font-family: 'Poppins', sans-serif;
  margin: 0;
}

input{
  font-family: 'Poppins';
}

.Mui-selected{
  color: $white !important;
  background-color: $black !important;
  border: solid 1px $light-green !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0);
}