@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";
.foldersList{
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.foldersHeader{
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 16px 5px;
  gap: 5px;
}

.customInput{
  font-size: 0.9rem;
  padding: 8px 34px 8px 15px;
  border-radius: 7px;
  font-family: 'Poppins';
  width: calc(100% - 32px);

  outline: none;
  border: solid 1px $font-light-grey;
}

.inputField{ 
  display: flex;
  width: 100%;
  align-items: center;
}

.searchIcon{
  font-size: 1.1rem;
  width: 0;
  position: relative;
  right: 1.7rem;
  color: $font-light-grey;
}

.foldersSubHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.foldersLabel{
  font-weight: 500;
  color: $font-deep-grey;
  font-size: 1.1rem;
}

.buttonsContainer{
  display: flex;
  align-items: center;
  gap: 4px;
}

.headerButton{
  background-color: $black;
  color: white;
  border-radius: 7px;
  border: none;
  font-size: 0.9rem;
  padding: 10px 15px;
  font-family: 'Poppins';
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 7px;
}


.newRoleIcon{
  font-size: 1.3rem;
}


.foldersBody{
  height: 100%;
  padding: 0 0px 16px;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
