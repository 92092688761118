@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.employeeAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  // min-width: 56px;
  &.smallAvatar {
    min-width: min-content;
  }
  &.addMargin{
    margin-right: 0.5em;
  }
}

.smallSquare {
  font-size: 0.8rem;
  border-radius: 6px;
  width: 2.7em;
  height: 2.7em;
  min-width: 2.7em;
  min-height: 2.7em;
  max-width: 2.7em;
  max-height: 2.7em;
  display: grid;
  place-items: center;
  > .shortName {
    font-weight: bold;
  }
}

.normalSquare {
  font-size: 1em;
  border-radius: 9px;
  width: 2.7em;
  height: 2.7em;
  min-width: 2.7em;
  min-height: 2.7em;
  max-width: 2.7em;
  max-height: 2.7em;
  display: grid;
  place-items: center;
}

.shortName {
  font-size: 1.1em;
}

.skills {
  font-size: 8px;
  display: flex;
  align-items: center;
  gap: 2px;
}

.skillCirlce {
  display: grid;
  place-items: center;
}
