@import "../../global/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  z-index: 40;
  min-width: 20rem;
  max-height: 20rem;
}

.groupContainer {
  background-color: $grey;
  padding: 8px 8px 8px 15px;
  display: flex;
  font-weight: 600;
}

.groupName{
  padding-left: 0.1em;
}

.selectAll{
  // background-color: $dark-menu;
  padding: 0.4rem 0.4rem 0.4rem 15px;
  background-color: $light-green;
  color: $white;
  font-size: 0.95em;
}
