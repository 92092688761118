@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.contactCard {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey;
  border-right: 1px solid $grey;
  width: 100%;
  padding: 15px 0px 10px;

  @media #{$tablet-up} {
    width: 34vw;
    padding: 8px 15px
  }
}

.moveIcon::before {
  color: $medium-grey;
  font-size: 1.2em;
}

.iconContainer {
  width: 40px;
  height: 40px;
  padding: 0 0.5em;
}

.overtimeIcon{
  color: $white;
  font-size: 1.5rem;
}

.icon {
  width: 100%;
  height: 1.5em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  font-weight: 400;
  color: $white;
}

.qualificationContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 3px;
}

.bullet {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.name {
  font-size: 1em;
}

.id {
  font-size: 1em;
  font-weight: normal;
  color: $font-dark-grey;
}

.planeId {
  font-weight: bold;
  color: $font-dark-grey;
  font-size: 1em;
}

.iconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5em;
  margin-bottom: 7px;
  button {
    padding: 0;
    color: $black;
  }
}

.overtimeIconContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.iconOvertimeContainer {
  background-color: $black;
  width: 2.5em;
  height: 2.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  place-content: center;
  border: 1px solid $black;
  margin-right: 10px;

  @media #{$tablet-up} {
    margin-right: 0px;
  }
}

.overtimeNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background-color: $light-green;
  color: $white;
  border-radius: 50%;
  font-size: 12px;
  margin-left: -8px;
  margin-bottom: -6px;
}


.contactCardInfoContainer {
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin-left: 10px;

  @media #{$tablet-up} {
    margin-left: 0px;
  }
}

.iconUserCard {
  font-size: 1.5em;
}

.iconUserCardContainer {
  background-color: $light-grey;
  width: 2.5em;
  height: 2.5em;
  border-radius: 5px;
  display: flex;
  align-items: center;
  place-content: center;
  border: 1px solid $white;
  margin-right: 10px;

  @media #{$tablet-up} {
    margin-right: 0px;
  }
}

.shiftPatternContainer {
  width: auto;
  font-size: 0.9em;
}

.selectEmployeeContainer {
  margin-top: 8px;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    color: $white;
    font-weight: bold;
    gap: 1em;
    margin-right: 0.5em;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: 1px solid $font-dark-grey;
  border-radius: 5px;
}


/* When the checkbox is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: $light-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.greyBackground{
  background-color: $light-grey;
}

.contactCardMonthView {
  display: flex;
  align-items: center;
  border: none;
  width: auto;
  font-size: 0.88rem;
  padding: 15px 7px 6px;

  .iconContainer {
    width: 2rem;
    height: auto;
    padding: 0 0.5rem;

    .icon {
      height: auto;
      width: auto;
      font-size: 1.2rem;
    }
  }

  .id {
    font-size: 0.95em;
  }
}