@import "../../global/colors.scss";

.calendarContainer {
  display: flex;
  background-color: white;
  flex-direction: column;
  position: absolute;
  top: 0;
  border-radius: 10px;
  color: black;
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0;
  span {
    flex: 1;
    font-weight: 400;
    font-size: 1rem;
  }
  button {
    background-color: $light-green;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
  }
}

.rdp {
  background-color: transparent;
}

.rdp-caption_label,
.rdp-vhidden {
  display: none;
}

.rdp-caption_dropdowns {
  display: flex;
  padding: 1rem 0.5rem;
}

.rdp-dropdown_month {
  flex: 1;
}

.rdp-dropdown {
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.rdp-button.rdp-day {
  background-color: $light-grey;
  border: none;
  height: 2.3rem;
  width: 2.3rem;
  border-radius: 5px;
  color: $black;
  font-weight: bold;
  cursor: pointer;
}

.rdp-head_row {
  color: $font-dark-grey;
  text-transform: uppercase;
  font-size: 0.7rem;
}

.rdp-button.rdp-day.rdp-day_outside {
  color: $font-light-grey;
}

.rdp-button.rdp-day.rdp-day_selected {
  background-color: $black;
  color: $white;
  border: 1px solid $light-green;
}
