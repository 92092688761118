@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.container {
  background-color: $shift-pattern-light-grey-bg;
  width: 100%;
  min-height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
	overflow: auto;
  height: 100%;
	display: flex;
	flex-direction: column;
}

.header {
  display: flex;
  padding: 1rem 1.5rem;
}

.newShiftContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	// max-height: 30px;
	.userIcon {
		color: $light-green;
		font-size: 1.2rem;
		margin-right: 0.5rem;
		&::before {
			font-size: 1.5rem;
		}
	}
	.title {
		font-weight: bold;
		font-size: 1.2rem;
		margin-right: 2rem;
	}
	button {
		background-color: $dark-black;
		color: $white;
		border: none;
		padding: 0 16px;
		border-radius: 0.3rem;
		font-weight: 500;
		font-size: 0.8rem;
		height: 36px;
		cursor: pointer;
	}
}

.pageTitle{
	display: flex;
	align-items: center;
}

.body {
  display: flex;
  padding:  0 1.5rem 12px;
	flex: 1;
	overflow: auto;
  .patternList {
		display: flex;
		flex-direction: column;
		gap: 12px;
		min-width: 390px;
		box-sizing: border-box;
		padding-right: 20px;
  }
  .newPattern {
    background-color: $white;
    flex: 2;
    border-radius: 0.4rem;
    padding: 1rem 1.5rem;
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: 8px;
  }
}

.applyBtn {
  background-color: $light-green;
  color: $white;
  border: none;
  padding: 0 2rem;
  border-radius: 0.3rem;
  font-weight: 600;
  margin-left: 1rem;
	height: 36px;
  cursor: pointer;
}

.discardBtn {
  background-color: $black;
  margin-left: 0.5rem;
}