@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.hamburgerMenu {
  position: relative;
  z-index: 30;
}

.menuBtn{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* set a fixed width */
  height: 50px;
  flex-shrink: 0; /* prevent the icon from shrinking */
  position: relative;
}

.iconClose::before {
  font-size: 1rem !important;
}

.menuBtnBurger {
  width: 100%;
  height: 2px;
  background-color: $white;
  transition: all 0.3s ease-in-out;
}

.menuBtnBurger::before,
.menuBtnBurger::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $white;
  transition: all 0.3s ease-in-out;
}

.menuBtnBurger::before {
  transform: translateY(-6px);
}

.menuBtnBurger::after {
  transform: translateY(6px);
}

.open .menuBtnBurger {
  transform: rotate(45deg);
  background-color: transparent;
}

.open .menuBtnBurger::before {
  transform: translateY(0) rotate(45deg);
}

.open .menuBtnBurger::after {
  transform: translateY(0) rotate(-45deg);
}

.menu {
  position: absolute;
  left: 0;
  background-color: $black;
  width: 16rem;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  z-index: 999;
}

.menu.open {
  height: auto;
}

.menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu li {
  padding: 1rem;
  text-align: initial;
  border-bottom: 1px solid $border-menu;
  cursor: pointer;
}

.menu a {
  color: $white;
  text-decoration: none;
}
