@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.switch {
  display: flex;
  width: 52px;
  height: 26px;
  min-width: 52px;
  min-height: 26px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
}

.slider {
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 3px;
  // cursor: pointer;

  width: 100%;
  -webkit-transition: .4s;
  transition: .4s;
}

.clickable{
  cursor: pointer;
}

.disabled {
  opacity: 0.7;
}

.slider:before {
  content: "";
  height: 19px;
  width: 19px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  transition: .4s;
}

input:focus + .slider {
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}