@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.fillSpace{
  height: 100%;
  width: 100%;
  overflow: auto;
}

.singleRowFields{
  display: flex;
  align-items: center;
  gap: 0;
}

.colorField{
  width: min-content;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  gap: 6px;
  > span {
    white-space: nowrap;
  }
}

.container{
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  gap: 13px;
}

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roleTitle{
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 12px;
}

.roleLabel{
  font-weight: 500;
  color: $font-deep-grey;
  font-size: 1.1rem;
  line-height: 1.2;
}

.buttonsContainer{
  display: flex;
  align-items: center;
  gap: 10px;
}


.headerButton{
  background-color: $black;
  color: white;
  border-radius: 7px;
  border: none;
  font-size: 0.9rem;
  padding: 8px 15px;
  min-height: 38px;
  font-family: 'Poppins';
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.saveImage{
  width: 20px;
}

.folderIcon{
  font-size: 1.3rem;
  color: $light-green;
}

.roleIcon{
  font-size: 1.7rem;
  color: $light-green;
}

.typeRadioGroup{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  align-items: center;
}

.radioButton{
  display: flex;
  align-items: center;
  padding: 8px 10px;
  gap: 5px;
  border-radius: 7px;
  background-color: $light-grey;
  cursor: pointer;
}

.radioButton:hover{
  background-color: fade-out($light-green, 0.8);
}

.selectedRadioOption{
  background-color: fade-out($light-green, 0.8);
}

.radioCircle{
  min-width: 17px;
  min-height: 17px;
  border-radius: 50%;
  border: solid 1px $grey;
  background-color: white;
  display: grid;
  place-items: center;
}

.radioInnerCircle{
  min-width: 12px;
  min-height: 12px;
  border-radius: 50%;
  background-color: transparent;
}

.selectedInnerCircle{
  background-color: $light-green;
}

.radioButtonLabel{
  font-size: 0.85rem;
  color: $black;
  font-weight: 500;
}

.field{
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.descriptionField{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.disabled{
  pointer-events: none;
  opacity: 0.7;
}

.nameLabel{
  padding-left: 15px;
  color: $font-deep-grey;
  font-size: 0.9rem;
  font-weight: bold;
}

.colorSquare{
  margin-left: 15px;
  cursor: pointer;
  min-width: 2.2rem;
  min-height: 2.2rem;
  max-width: 2.2rem;
  max-height: 2.2rem;
  border-radius: 7px;
  width: min-content;
  height: min-content;
  &.disabledPicker {
    cursor: default;
    opacity: 0.3;
  }
}

.nameInput{
  font-size: 0.9rem;
  padding: 8px 15px;
  border-radius: 7px;
  font-family: 'Poppins';
  width: calc(100% - 32px);
  outline: none;
  border: solid 1px $font-light-grey;
}

.descriptionInput{
  font-size: 0.9rem;
  padding: 8px 15px;
  border-radius: 7px;
  font-family: 'Poppins';
  width: calc(100% - 32px);
  outline: none;
  color: $black;
  border: solid 1px $font-light-grey;
  resize: vertical;
  width: calc(100% - 32px);
  height: 140px;
  @media (max-width: calc(1275px + 46px + 30px)) {
    height: 80px;
  }
}


.noSelection{
  font-weight: normal;
  font-size: 0.8em;
}
