@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.container {
  display: grid;
  grid-template-columns: 5fr 4fr;
  height: calc(100vh - 8rem);
  place-content: center;
  margin: 4rem;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .title {
    color: $font-dark-grey;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .info {
    font-size: 0.8rem;
    margin: 2rem;
    color: black;
    font-weight: 400;
  }
}

.loginContainer {
  display: flex;
  margin: 2rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 20rem;
  }
  @media (orientation: portrait) and (min-width: $mobile) {
    height: calc(100vh - 24rem);
    margin: 2rem 6rem;
    margin-top: 10rem;
  }
}

.LoginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  justify-content: space-around;
  .emailText {
    color: $font-dark-grey;
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.userAdminIcon::placeholder,
.passwordIcon::placeholder {
  color: $font-light-grey;
  font-weight: bold;
}

.userAdminIcon {
  background-image: url(../../assets/images/icon_user.png);
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 2rem;
  padding-left: 3rem;
}

.inputContainer {
  height: 7vh;
  background-color: $white;
  border: 2px solid $grey;
  border-radius: 10px;
  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.buttons {
  border-radius: 10px;
  height: 7vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.submitBtn {
  background-color: $light-green;
  color: $white;
  font-size: 1.3rem;
}