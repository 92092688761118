@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.titleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em;
  color: $white;
  font-size: 18px;
  font-weight: bold;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.buttonContainer {
  background-color: $bg-modal;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.container {
  width: 600px;
  height: 590px;
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 560px;
  max-height: 560px;
}

.saveButton {
  display: none;
  padding: 10px;
  background-color: $light-green;
  border: 1px solid $light-green;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 1em;
  color: $white;
  cursor: pointer;

  span {
    padding-right: 5px;

    &::before {
      font-size: 1em;
    }
  }

  @media #{$tablet-up} {
    display: block;
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.iconClose::before,
.iconDelete::before {
  color: $white;
}

.verticalLine {
  height: 1px;
  background-color: $font-dark-grey;
  border: none;
}

.commentsContainer {
  display: flex;
  flex-direction: column;
  padding: 1em 1em;
  color: $white;
  gap: 1em;
}

.comments {
  background-color: $comment-bg;
  padding: 1em;
  font-size: 11px;
  border-radius: 8px;
  margin-top: 0.5em;
}

.commentsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 0.5em;
  font-size: 15px;
}

.commentInfo {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  color: $light-green;
}

.emptyComments {
  padding: 1em;
  color: $white;
  font-size: 15px;
}

.textArea {
  background-color: $dark-grey-bg;
  color: $white;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 1em;
}

.textArea::placeholder {
  padding: 0.25em;
  color: $font-dark-grey;
}
