@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.header {
  display: flex;
  align-items: flex-start;
  width: 100%;
  .title {
    color: $font-light-grey;
    font-weight: bold;
    flex: 1;
  }
	.infoAndButtons{
		display: flex;
		align-items: center;
	}
  .info {
    font-size: 0.7rem;
    color: grey;
  }
  .iconInfo {
    color: $light-green;
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
}

.applyBtn {
  background-color: $light-green;
  color: $white;
  border: none;
  padding: 0 2rem;
  border-radius: 0.3rem;
  font-weight: 600;
  margin-left: 1rem;
	height: 36px;
  cursor: pointer;
}


.saveBtn {
  border: none;
  padding: 0 2rem;
	height: 36px;
  border-radius: 0.3rem;
  background: $black;
  color: $white;
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: 0.5rem;
	font-weight: 600;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  span {
    color: $shift-pattern-title-color;
    font-weight: bold;
    font-size: 0.9rem;
		padding: 0 12px;
  }
  input {
    display: flex;
    padding: 0 0.8rem;
		height: 40px;
		box-sizing: border-box;
    border: 1px solid $grey;
    background-color: $white;
    border-radius: 0.4rem;
    color: $black;
		outline: none;
		&:focus-visible{
		}
  }

}

.daySpanContainer {
  display: flex;
  background-color: $shift-pattern-bg;
  padding: 8px 20px;
  border-radius: 0.4rem;
  align-items: center;
  .title {
    color: $shift-pattern-day-title-color;
    font-weight: bold;
    flex: 1;
  }
  .info {
    color: $shift-pattern-info-color;
    font-size: 0.8rem;
    margin-right: 1rem;
  }
  .addBtn {
    background-color: $light-green;
    margin-right: 0.5rem;
    color: $white;
    border-radius: 0.4rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
  }
  input {
    display: flex;
    padding: 0 0.8rem;
		height: 40px;
		box-sizing: border-box;
    border: 1px solid $grey;
    background-color: $white;
    border-radius: 0.4rem;
		text-align: right;
    color: $black;
		outline: none;
		&:focus-visible{
		}
  }
}

.configureDaysContianer {
  display: flex;
  flex-direction: column;
	overflow: auto;
	gap: 8px 	 ;
	// padding: 0 8px;
  .title {
    font-weight: bold;
    color: $font-dark-grey;
		padding: 0 20px;
    // margin-bottom: 1rem;
  }
}

.dayCardContainer {
  background-color: $black;
  color: $shift-pattern-grey-color;
  width: 8rem;
  height: 11rem;
  padding: 1rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  .day {
    flex: 1;
    span {
      color: $white;
      font-weight: bold;
    }
  }
  .infoText {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.daysContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
	overflow: auto;
	justify-items: center;
	padding: 0 12px;
}

.iconDelete {
  border: none;
  padding: 0.6rem;
  border-radius: 0.3rem;
  background: $black;
  color: $white;
  font-size: 0.8rem;
  cursor: pointer;
  margin-left: 0.5rem;
}