@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.roleCard {
  background-color: $white;
  padding: 0.5rem;
  display: flex;
  margin: 0.5rem;
  overflow-y: auto;
  cursor: pointer;
}

.cardInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  .title {
    font-weight: bold;
  }
  .info {
    font-size: 0.8rem;
    color: $black;
    font-weight: 500;
    .resourcetitle {
      color: $light-green;
    }
    .resource {
      color: $black;
    }
    span {
      margin-right: 0.2rem;
    }
  }
  .type {
    color: $font-light-grey;
  }
}

.deleteBtn {
  border: none;
  background-color: $white;
  font-size: 1.2rem;
  color: $black;
  cursor: pointer;
}

.iconMove {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  color: $font-light-grey;
}