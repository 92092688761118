@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.dialog {
  background-color: $black;
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.dialogButton{
  background-color: $light-green;
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  font-family: "Poppins";
  font-weight: 500;
  color: $white;
  cursor: pointer;
  &.cancelButton{
    background-color: $fade-white;
  }
}