@import "../../global/colors.scss";

.permissionContent {
  border: 1px solid $light-green;
  border-top: none;
  padding: 1rem;
}

.container {
  display: flex;
  padding: 0.5rem 0;
  align-items: center;
}

.dateListContainer {
  max-height: 13rem;
  overflow-y: auto;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  flex: 1;
}

.date {
  color: $white;
}

.dateSubtitle,
.businessDays {
  color: $font-dark-grey;
}

.viewMore {
  background-color: $filter-input-hover-bg;
  border: none;
  color: white;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.commentsContainer {
  display: flex;
  align-items: center;
}

.leavesContainer {
  display: flex;
  flex-direction: column;
  text-align: end;
  margin-right: 1rem;
  .leave {
    color: $white;
  }
}

.commentIconContainer {
  background-color: $bg-modal;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  span::before {
    color: $white;
  }
}

.iconDelete {
  color: $white;
  margin: 0 1rem;
  cursor: pointer;
}