@import "../../global/colors.scss";

.register {
  padding: 9px 6px;
  text-align: left;
  align-items: center;
  border-radius: 5px;
  color: $white;
  margin: 5px;
  display: flex;
  height: calc(100% - 30px);
  cursor: pointer;
}

.registerMonth {
  color: $white;
  display: flex;
  align-items: center;
  height: calc(100% - 4px);
  width: 100%;
  justify-content: center;
  cursor: pointer;

}

.shiftName {
  flex: 1;
}

.name {
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  flex-wrap: wrap;
}

.weekName{
  & *[class*="icon-check"] {
    position: relative;
    bottom: 0.1em;
    font-size: 0.9em;
  }

  & *[class*="icon-overtime"], *[class*="icon-time-changed"]{
    font-size: 0.95em;
    font-weight:lighter;
  }
}

.monthName{
  font-size:0.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2em;
  justify-content: center;
  padding: 10px 0;
  & *[class*="icon-check"] {
    position: relative;
    bottom: 0.1em;
    z-index: 0;
  }
}

.monthShortName{
  font-weight: bold;
  line-height: 1;
}


.shiftInfoColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nameDescription {
  font-size: 0.8em;
}

.iconCheck::before {
  font-size: 0.8em;
}

.iconOvertime::before {
  font-size: 0.8em;
}

.monthViewDayContainer {
  // .name {
  //   font-size: 0.9em;
  //   font-weight: 600;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   line-height: 1;
  //   padding: 0.4rem 0 0.1em 0;
  //   gap: 0.2em;
  //   div {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 0.3em;
  //   }
  //   .iconOvertime {
  //     font-size: 0.8rem;
  //   }
  // }
}
