@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.header {
  font-family: "Poppins";
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  font-size: 1.1rem;
}

.title {
  display: flex;
  gap: 10px;
  align-items: baseline;
  font-weight: bold;
}

.dialogContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.closeButton {
  font-size: 1.7rem;
  font-weight: normal;
  cursor: pointer;
  color: $font-deep-grey;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 420px;
}

.createButton {
  background-color: $light-green;
  cursor: pointer;
  display: grid;
  place-items: center;
  color: $white;
  padding: 9px 80px;
  border-radius: 7px;
  font-weight: bold;
  font-size: 0.9rem;
}

.buttonsBar {
  width: 100%;
  display: grid;
  place-items: center;
  margin-top: 15px;
}

.nameLabel {
  padding-left: 15px;
  color: $font-deep-grey;
  font-size: 0.9rem;
  font-weight: bold;
}

.nameInput {
  font-size: 0.9rem;
  padding: 8px 15px;
  border-radius: 7px;
  font-family: "Poppins";
  width: calc(100% - 33px);
  outline: none;
  border: solid 1px $font-light-grey;
}

.roleIcon {
  font-size: 1.5rem;
  position: relative;
  top: 5px;
}