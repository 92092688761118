@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.backdrop{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: fade-out(white, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30;
}

.loadingGif{
  width: 60px;
  z-index: 2;
}