@import "../../global/colors.scss";

.scheduleList{
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  overflow: auto;
  padding: 2px 2px;
}

.scheduleContent{
  padding: 0.3em;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  gap: 0.3em;
  background-color: $bg-modal;
  color: $white;
  h3 {
    font-size: 0.8rem;
    margin: 0;
    padding: 0.7em;
    width: 100%;
    color: $font-dark-grey;
    font-style: italic;
    text-align: center;
    box-sizing: border-box;
    font-weight: normal;
  }
}

.schedule {
  display: grid;
  background-color: $black;
  grid-template-columns: min-content min-content auto;
  gap: 0.8em;
  padding: 0.4em;
  align-items: center;
  border: solid 1px $black;
  border-radius: 0.4em;
  transition: all 0.3s ease;
  >i{
    color: $font-dark-grey;
  }
  h1{
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }
  h2{
    font-size: 0.75rem;
    color: $font-dark-grey;
    margin: 0;
    padding: 0;
  }
}

.highlight{
  border: solid 1px $light-green;
  transition: all 0.3s ease;
  background-color: fade-out($light-green, 0.93);
  i, h2{ 
    color: $light-green;
  }

}

.highlightButton{
  background-color: $light-green;
}

.buttons{
  display: flex;
  align-items: center;
  gap: 0.3em;
  width: 100%;
  >button{
    font-size: 0.8rem;
    border: none;
    height: 2.7em;
    width: 100%;
    min-width: 10em;
    display: grid;
    place-items: center;
    font-weight: 500;
    color: $white;
    border: none;
    border-radius: 0.4em;
    background-color: $fade-white;
    cursor: pointer;
    &.green{
      background-color: $light-green;
    }
  }
}