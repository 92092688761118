@import '../../global/colors.scss';
.microsoftButton{
  font-size: 17px;
  background-color: $microsoft-black;
  height: 41px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 0.2em;
  gap: 12px;
  color: $white;
  cursor: pointer;
  border: none;
  width: min-content;
  >span{
    white-space: nowrap;

  }
  margin-left: auto;
  margin-right: auto;
}

.buttonLabel{
  font-weight: 500;
}