@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.iconArrow{
  font-size: 9px;
  color: $font-dark-grey;
}

.placeholder{
  color: $font-light-grey;
  font-style: italic;
  font-size: 0.9em;
  padding: 0 1em;
}

.summaryAndDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px $border-bg-dropdown;
  cursor: pointer;
}

.incomplete{
  color: $deep-red;
}

.complete{
  color: $light-green;
}

.daySummary{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 1260px) {
    flex-direction: column;
    padding: 4px 0 0 0;
  }
}
.dayShiftInfo{
  display: flex;
  gap: 5px;
  align-items: center;
}

.shiftsLabel{
}
.shiftsCount{
  font-weight: 500;
}
.dayShiftInfo{
  display: flex;
  gap: 5px;
  align-items: center;
}
.shiftsLabel{
}

.allocatedCount {
  font-weight: 500;
}


.siteAndShiftDate{
  font-size: 17px;
  color: white;
  padding: .3em 1em;
}

.verticalLine{
  margin: 0;
  border-color: black;
}

.emptyShiftAndSites{
  font-size: 14px;
  color: white;
  padding: .3em 1em;
}

.siteAndShiftCard{
  color: white;
  background-color: $selected-week-day-bg;
  margin: .5em 1em;
  border-radius: .5em;
  height: 3em;
  display: flex;
  flex-direction: row;
  padding-right: 1em;
  justify-content: space-between;
}

.siteAndShiftCardLeftContainer{
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.siteAndShiftLeftCard{
  height: 3em;
  width: 7.5em;
  border-radius: .5em;
  padding-left: .75em;
  font-weight: 500;
}

.siteAndShiftLeftCardHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .25em;
  margin-top: 2px;
}

.siteAndShiftCardText{
  font-size: 12px;
}

.cardName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.shiftAllocatedText{
  margin-top: .5em;
  color: $font-light-grey;
}

.shiftAllocatedNumbers {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.lowNumber{
  color: $light-green;
}