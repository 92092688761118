@import url("../src/assets/fonts/icomoon/style.css");
@import "../src/assets/fonts/font/font.scss";

body {
  margin: 0;
  font-family: 'poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: unset !important;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
