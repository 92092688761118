@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.header {
  padding: 1rem;
  color: $border-bg-dropdown;
  font-weight: 500;
}

.cardContainer {
  padding: 1rem;
  div {
    display: flex;
    background-color: $light-grey;
    padding: 1.5rem 1rem;
    align-items: center;
    border-radius: 0.3rem;
    border: 1px solid $border-card;
    margin-bottom: 1rem;
    &:hover {
      background-color: $row-hover-bg;
      border: 1px solid $light-green;
      cursor: pointer;
    }
  }
  div.selected {
    background-color: $row-hover-bg;
    border: 1px solid $light-green;
  }
}

.icon {
  color: $light-green;
  font-size: 1.5rem;
  margin-right: 1rem;
}