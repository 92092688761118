@import "../../global/colors.scss";

.site {
  font-size: 1rem;
  background-color: $dark-menu;
  padding: 0.7em;
  border-radius: 0.6em;
  // flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.placeholder{
  font-size: 1rem;
  color: $font-dark-grey;
  font-weight: normal;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 1em;
  height: 100%;
  white-space: nowrap;
  padding: 0 3em;
  box-sizing: border-box;
  >h6{
    font-size: 0.9em;
    font-weight: 500;
    font-style: italic;
    margin: 0;
    padding: 0;
  }
}

.siteHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
}

.iconTitle {
  display: flex;
  align-items: center;
  gap: 0.3em;
  > i {
    color: $light-green;
  }
  > h1 {
    font-size: 0.95rem;
    margin: 0;
    white-space: nowrap;
  }
}

.siteSummary {
  display: flex;
  align-items: center;
  gap: 0.5em;
  > h2 {
    font-size: 0.75rem;
    margin: 0;
    font-weight: normal;
    white-space: nowrap;
  }
  > button {
    min-height: 2.4em;
    height: 2.4em;
    min-width: 2.4em;
    width: 2.4em;
    color: $white;
    display: grid;
    place-items: center;
    border-radius: 0.5em;
    background-color: $black;
    cursor: pointer;
  }
}

.siteContent {
  display: grid;
  grid-template-columns: min-content auto repeat(7, min-content);
  gap: 0.4em 0.3em;
  height: 100%;
  box-sizing: border-box;
  align-content: center;
  span {
    font-size: 0.65em;
    line-height: 1.1;
    font-weight: normal;
    margin: 0;
    white-space: nowrap;
  }
  h4 {
    font-size: 0.65em;
    line-height: 1.1;
    font-weight: normal;
    margin: 0;
    grid-column: span 2;
  }
  h3 {
    font-size: 0.9em;
    margin: 0;
    line-height: 1;
    height: 1.7em;
    min-width: 1.7em;
    display: grid;
    align-items: end;
    justify-content: center;
    height: 100%;
  }
  > input {
    background-color: transparent;
    border: solid 1px $medium-grey;
    max-width: 3.5em;
    border-radius: 0.4em;
    outline: none;
    color: $white;
    text-align: center;
    transition: all 0.4s ease;
    &.dayInput {
      font-size: 1rem;
      box-sizing: border-box;
      outline: none;
      border: none;
      background-color: $black;
      width: 100%;
      border-radius: 0.4em;
      min-width: 1.9em;
      padding: 0 0.3em;
      max-width: 2.5em;
      height: 1.9em;
      display: grid;
      place-items: center;
      white-space: nowrap;
      overflow: hidden;
      &.highlight {
        background-color: $dark-faded-green;
        color: $light-green;
        transition: all 0.4s ease;
      }
      &.error {
        background-color: fade-out($deep-red, 0.5);
      }
    }
  }
}

.greenBackground {
  background-color: $dark-faded-green;
}

.greenColor {
  color: $light-green;
}


.errortxt {
  color: $deep-red;
}

.slot{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.3em;
}

.slotTitle{
  display: flex;
  align-items: center;
  color: $font-light-grey;
  gap: 0.2em;

  h5{
    margin: 0;
    font-size: 0.85em;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1;
    max-width: 6rem;
    white-space: nowrap;
  }
  >i{
    font-size: 0.7em;
  }
}