@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.loginContainerDesktop {
  display: flex;
  @media (min-aspect-ratio: 4/3) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100vh;
  }
}

.logingImagesContainer {
  display: none;
  @media (min-aspect-ratio: 4/3) {
    display: flex;
    background-image: url("../../assets/images/login_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    img {
      position: absolute;
      width: calc( 100% - 39vw);
      border-radius: 20px;
      top: 4rem;
      left: 4rem;
      box-shadow: 5px 10px 18px $black;
    }
  }
}

.loginImgTablet {
  display: none;
  @media (orientation: portrait) and (min-width: $mobile) {
    display: block;
    position: absolute;
    width: 65%;
    border-radius: 20px;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.textContainer {
  color: white;
  z-index: 1;
  top: 6rem;
  position: absolute;
  left: 6rem;
  display: flex;
  flex-direction: column;
  width: 50%;
  .title {
    font-size: 4rem;
    font-weight: bold;
    @media #{$laptop-query} {
      font-size: 3rem;
    }
  }
  .description {
    font-size: 2rem;
    font-weight: 100;
    margin-top: -1rem;
    @media #{$laptop-query} {
      font-size: 1rem;
    }
  }
}

.loginContainerMobile {
  width: 100%;
}

.loginContainer {
  height: calc(100vh - 8rem);
  display: flex;
  margin: 2rem;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  img {
    width: 280px;
  }
  @media (orientation: portrait) and (min-width: $mobile) {
    height: calc(100vh - 24rem);
    margin: 2rem 6rem;
    margin-top: 10rem;
  }
  >*:first-child{
    margin-bottom: 0.5rem;
  }
  >*:last-child{
    margin-top: 0.5rem;
  }
}

.companyName {
  font-size: 1.5rem;
}

.colorBlackContainer,
.colorGreenContainer {
  height: 2rem;
  width: 100%;
  @media (orientation: portrait) and (min-width: $mobile) {
    height: 4rem;
  }
  @media (orientation: landscape) {
    display: none;
  }
}

.colorGreenContainer {
  background-color: $grey;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  @media (orientation: portrait) and (min-width: $mobile) {
    background-color: $black;
    border-radius: 0;
    border-bottom: 2rem solid $grey;
  }
}

.LoginForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60%;
  justify-content: space-around;
}

.welcomeText {
  font-size: 2rem;
  font-weight: bold;
  color: $font-dark-grey;
}

.inputContainer {
  height: 7vh;
  background-color: $white;
  border: 2px solid $grey;
  border-radius: 10px;
  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.userAdminIcon::placeholder,
.passwordIcon::placeholder {
  color: $font-light-grey;
  font-weight: bold;
}

.userAdminIcon {
  background-image: url(../../assets/images/icon_user.png);
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 2rem;
  padding-left: 3rem;
}

.passwordIcon {
  background-image: url(../../assets/images/icon_password.png);
  background-repeat: no-repeat;
  background-position: 0.5rem center;
  background-size: 2rem;
  padding-left: 3rem;
}

.buttons {
  border-radius: 10px;
  height: 7vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.submitBtn {
  background-color: $light-green;
  color: $white;
  font-size: 1.3rem;
}

.subscribeBtn {
  background-color: $light-grey;
  border-radius: 5px;
  font-weight: 400;
}


.underLineText {
  text-decoration-line: underline;
}

.colorBlackContainer {
  background-color: $black;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  @media (orientation: portrait) and (min-width: $mobile) {
    border-radius: 0;
    border-top: 2rem solid $grey;
  }
}
