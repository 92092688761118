@import "../../global/media_query_variables.scss";

.headerMainContainer {
  width: 100%;
}

.headerTopNoSummary {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 159px;
    margin-bottom: 50px;
  }
  @media(max-width: 1260px) and (min-width: 576px) {
    margin-top: 159px;
    margin-bottom: 50px;
  }
}

.headerTop {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 191px;
    margin-bottom: 50px;
  }
  @media (max-width: 1260px) and (min-width: 576px) {
    margin-top: 206px;
    margin-bottom: 50px;
  }
}

.headerTopNoMarginBottom {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 110px;
    margin-bottom: 0;
  }
}