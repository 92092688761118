@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 1em;
  position: sticky;
  top: 0;
  background-color: $white;
}

.searchInput {
  display: flex;
  width: 100%;
  align-items: center;
  flex: 4;
}

.locationButtons{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3em;
}

.searchIcon {
  font-size: 1.1rem;
  width: 0;
  position: relative;
  right: 1.7rem;
  color: $grey;
}

.inputUser {
  height: 2.8em;
  width: 100%;
  border: 1px solid $grey;
  border-radius: 7px;
  padding-left: 1em;
  padding-right: 2.5rem;
  outline: none;
  font-weight: 500;
  font-size: 0.9rem;

  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.addNewItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: $white;
  font-weight: bold;
  background-color: $black;
  height: 3em;
  border-radius: 7px;
  cursor: pointer;
  flex: 2;
  font-size: 0.9rem;
}

.cardInfo {
  display: flex;
}

.icon {
  font-size: 1.3rem;
}

.iconLocation {
  color: $light-green;
  font-size: 2rem;
  align-self: center;
  margin-right: 0.5rem;
}

.emptyList {
  padding-left: 2em;
  font-size: 15px;
  color: $font-dark-grey;
}

.userCard:nth-child(odd) {
  background-color: $light-grey;
}

.userCard {
  border-radius: 7px;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 1px $grey;
  margin-bottom: 0.5em;
  cursor: pointer;

  &:hover {
    background-color: $row-hover-bg;
    border: 1px solid $light-green;
  }

  &:hover [class*='deleteIcon'] {
    display: grid;
    place-items: center;
  }
}

.title {
  font-weight: 500;
}

.subtitle {
  font-size: 0.8rem;
  color: $border-bg-dropdown;
}

.deleteIcon {
  font-size: 1.2em;
  background-color: $black;
  color: $white;
  // padding: 0.7rem;
  height: 2em;
  width: 2em;
  border-radius: 0.5rem;
  display: none;
  & [class*="icon-required-shifts"]{
    font-size: 1.2em;
  }
}

.userCard.selected {
  background-color: $row-hover-bg;
  border: 1px solid $light-green;
}

.blinkEffect {
  animation: blinkAnimation 0.9s infinite;
}

@keyframes blinkAnimation {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: $row-hover-bg;
  }
  100% {
    background-color: transparent;
  }
}