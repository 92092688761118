@import "../../global/colors.scss";

.userInfoContainer {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: $white;
  background-color: $bg-modal;
  border-radius: 10px;
  height: 3.2rem;
  padding: 0 1rem;
}

.qualificationContainer {
  span {
    font-size: 0.7em;
    color: $font-dark-grey;
  }
  div {
    display: flex;
    justify-content: flex-end;
  }
}

.bullet {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
}
