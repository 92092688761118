@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.roleAndSaveButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5em;
    color: $white;
    font-weight: bold;
    background-color: black;
    width: 9em;
    height: 3em;
    border-radius: 7px;
    cursor: pointer;

  &.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

}

.buttonLabel{
  white-space: nowrap;
}

.roleIcon {
  font-size: 1.3rem;
}

.confirmText {
    color: $white;
    padding: 1em;
    font-size: 15px;
    text-align: center;
    z-index: 2000;
}

.iconClose::before,
.iconDelete::before {
    color: $white;
}

.buttonContainer {
    background-color: $black-btn;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.confirmButton {
    padding: 10px;
    background-color: $light-green;
    border: 1px solid $light-green;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 1em;
    color: $white;
    cursor: pointer;

  span {
    padding-right: 5px;

    &::before {
      font-size: 1em;
    }
  }

  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  padding-top: 0;
  .title {
    font-weight: bold;
    flex: 1;
    .icon {
      font-size: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

.inputUser {
  height: 2.8em;
  width: 97%;
  background-color: $white;
  border: 1px solid rgb(194, 194, 194);
  outline: none;
  font-family: "poppins";
  padding-left: 0.5rem;

  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.searchIcon::placeholder {
  color: $font-light-grey;
}

.searchIcon {
  position: relative;
  background-image: url(../../assets/images/search.svg);
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  background-size: 1.5rem;
}

.dragAndDrop {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  color: $black;
}

.header {
  height: 2.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.dragAndDropHeader {
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  color: $font-light-grey;
  border: 1px dashed $font-light-grey;
  margin: 1rem;
}

.listContainer {
  background-color: $shift-pattern-bg;
  padding: 0.2rem;
  margin-top: 1rem;
  width: auto;
  max-height: 25rem;
  min-height: 25rem;
  overflow-y: auto;
}