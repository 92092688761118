@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.folderContainer {
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.folder {
  padding: 7px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  color: $font-deep-grey;
}

.folder:hover {
  background-color: $light-grey;
}

.folder:hover .deleteIcon {
  visibility: visible;
}

.folder:hover .editIcon {
  visibility: visible;
}

.folder:hover .roleIcon {
  visibility: visible;
}

.deleteIcon:hover {
  color: $red;
}

.editIcon:hover {
  color: $light-green;
}

.roleIcon:hover {
  color: $light-green;
}

.folderItem:hover {
  background-color: $light-grey;
}

.folderItem:hover .deleteIcon {
  visibility: visible;
}

.itemOptions {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $font-deep-grey;
  padding-right: 16px;
}

.emptyPlaceholder{
  font-size: 0.8rem;
  color: $font-dark-grey;
  line-height: 1;
  display: flex;
  padding-bottom: 10px;
}

.selectedPlaceholder {
  background-color: $light-grey;
}

.editIcon {
  font-size: 1.1rem;
  cursor: pointer;
  transform: rotate(30deg);
  visibility: hidden;
}

.roleIcon {
  font-size: 1.3rem;
  cursor: pointer;
  visibility: hidden;
}


.folder.selectedItem{
  background-color: $light-grey;
  color: $black;
}

.folderItem.selectedItem{
  background-color: $light-grey;
  color: $black;
}

.folderLabel {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.arrowButton {
  height: 100%;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.itemLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.deleteIcon {
  cursor: pointer;
  // margin-right: 16px;
  visibility: hidden;
}

.folderItemsClosed {
  height: 0;
  opacity: 0;
  display: none;
}

.userImage {
  color: $font-deep-grey;
  font-size: 1.3rem;
}

.folderImage.activeIcon{
  color: $light-green;
}

.userImage.activeIcon{
  color: $light-green;
}

.folderItem {
  padding: 4px 0px;
  color: $font-deep-grey;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.folderImage {
  font-size: 1.1rem;
  margin-bottom: 2px;
  color: $font-deep-grey;
}

.closedArrow {
  transform: rotate(45deg);
  position: relative;
  bottom: 1px;
  color: $font-deep-grey;
  font-size: 0.8rem;
}

.openArrow {
  transform: rotate(135deg);
  position: relative;
  bottom: 6px;
  color: $font-deep-grey;
  font-size: 0.8rem;
}

.highlightText {
  color: $light-green;
}