@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.gridsScroller {
  overflow: scroll;
  height: 100vh;
}

.monthText{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.disabledRow{
	position: relative;
	background-color: $clear-grey !important;
	* {
		opacity: 0.9;
		pointer-events: none;		
	}
	&::after{
		content: "Not active";
		position: absolute;
		top: 8px;
		right: 8px;
		padding: 4px 12px;
		background-color: $clear-grey;
		border: solid 1px $font-dark-grey;
		color: $font-dark-grey;
		font-size: 12px;
		font-weight: bold;
		border-radius: 4px;
	}
}

.grid {
  display: grid;
  scroll-behavior: smooth;
  overflow: auto;
  // width: calc(100vw);
  max-height: calc(100vh - 60px);
  margin-top: 60px;
}

.columnHeader.isToday {
  background-color: $light-green;
  color: $white ;
}

.dayNumber.isToday{
  color: $white;
}

.monthNameContainer{
  display: grid;
  place-items: center;
  text-transform: uppercase;
  border-right: 1px solid $black-btn;
  border-left: 1px solid $black-btn;
  border-top:  1px solid $black-btn;
  border-bottom:  1px solid $black-btn;
  color: $white;
  background-color: $black-btn;
  position: sticky;
  top: 0;
  z-index: 2
}

.monthName{
  width: 100%;
  height: 1.5rem;
  display: grid;
  border-right: 1px solid $light-green;
  border-left: 1px solid $light-green;
  border-top:  1px solid $black-btn;
  border-bottom:  1px solid $black-btn;
  place-items: center;
}

.monthRowElement{
  height: 38px;
  background-color: $black-btn;
  border-right: 1px solid $black-btn;
  border-left: 1px solid $black-btn;
  position: sticky;
  top: 0;
  z-index: 2
}

.transferIcon{
  margin-right: 1em;
}

.rowHeader {
  background-color: white;
  border-bottom: 1px solid $grey;
  border-right: 1px solid $grey;
  display: grid;
  z-index: 1;
}

.divisorLine {
  border-left: solid 1px $divisor-line;
}

.groupHeader {
  position: sticky;
  top: calc(60px + 38px);
  background-color: $grey;
  padding: 8px;
  font-weight: 600;
  z-index: 5;
  height: min-content;
}

.stickyPlaceholder {
  position: sticky;
  top: 38px;
}

.groupEntry {
  background-color: $grey;
  z-index: 4;
  position: sticky;
  top: calc(60px + 38px);
  width: 100%;
  border: none;
}

.gridEntry,
.gridHeader {
  background-color: $white;
}

.gridHeader {
  position: sticky;
  top: 38px;
  z-index: 2;
  height: 60px;
}

.columnHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  border-right: solid 1px $grey;
  text-transform: uppercase;
  color: $font-dark-grey;
}

.greyBackground {
  background-color: $light-grey;
}

.gridTitle {
  color: $black;
  font-weight: bold;
  display: flex;
  padding: 0 0.6rem;
  align-items: center;
  border-right: solid 1px $grey;
  font-size: 0.9rem;
}

.gridHeader.fixedLeft {
  z-index: 3;
}

.fixedLeft {
  position: sticky;
  left: 0;
}

.mainGrid {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
}

.dayNumber {
  font-size: 1.3em;
  font-weight: bold;
  color: $black;
}

.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
}

.placeholderContent {
  background: linear-gradient(90deg, $grey, $light-grey);
  background-size: 600% 600%;
  animation: loadingAnimation 1.5s ease-in-out infinite;
  height: calc(100% - 0.6rem);
  width: calc(100% - 0.35rem)  ;
  border-radius: 4px;
}

@keyframes loadingAnimation {
  0% {
    background-position: 10% 50%;
  }
  50% {
    background-position: 80% 50%;
  }
  100% {
    background-position: 10% 50%;
  }
}