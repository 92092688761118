@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.contentRowContainer {
  display: flex;
  flex-direction: column;
  &:nth-child(odd) {
    background-color: $light-grey;
  }
  @media #{$tablet-up} {
    flex-direction: row;
  }
}

.disabledRow{
	position: relative;
	background-color: $clear-grey !important;
	* {
		opacity: 0.9;
		pointer-events: none;		
	}
	&::after{
		content: "Not active";
		position: absolute;
		top: 8px;
		right: 8px;
		padding: 4px 12px;
		background-color: $clear-grey;
		border: solid 1px $font-dark-grey;
		color: $font-dark-grey;
		font-size: 12px;
		font-weight: bold;
		border-radius: 4px;
	}
}

.transferContainer {
  background-color: $grey;
  padding: 8px;
  display: flex;
  font-weight: 600;
  position: sticky;
  align-items: center;
}

.groupContainer {
  background-color: $grey;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 0.2em;
  font-weight: 600;
  position: sticky;
  z-index: 1;

  @media #{$tablet-up} {
    top: 191px;
    &.pendingWeek{
      top: calc(191px + 30px);
    }
  }
  @media (max-width: 1260px) {
    top: 207px;
    &.pendingWeek{
      top: calc(207px + 30px);
    }
  }
  @media #{$mobile-query} {
    top: 270px;
    &.pendingWeek{
      top: calc(270px + 30px);
    }
  }
}

.groupContainerNoSummary {
  background-color: $grey;
  padding: 8px;
  display: flex;
  font-weight: 600;
  align-items: center;
  gap: 0.2em;
  position: sticky;
  z-index: 1;

  @media #{$tablet-up} {
    top: 159px;
    &.pendingWeek{
      top: calc(159px + 30px);
    }
  }
  @media (max-width: 1260px) {
    top: 159px;
    &.pendingWeek{
      top: calc(159px + 30px);
    }
  }
  @media #{$mobile-query} {
    top: 270px;
    &.pendingWeek{
      top: calc(270px + 30px);
    }
  }
}

.contentRowContainer:hover:not(.disabledRow) {
  background-color: $row-hover-bg;
  border-top: 1px solid $light-green;
  border-bottom: 1px solid $light-green;
  cursor: pointer;
}