@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.shiftsBar{
  display: flex;
  background-color: $black;
  border-bottom: solid 1px $bg-modal;
  font-size: 12px;
  font-weight: 400;
  color: $white;
  align-items: center;
  width: 100%;
  @media #{$mobile-query} {
    display: none;
  }
}

.totalSummary{
  width: calc(34vw + 40px);
  display: flex;
  flex-wrap: wrap;
  border-right: solid 1px $border-bg-dropdown;
  align-items: center;
  justify-content: center;
}

.dailySummary{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.totalLabel{
  font-weight: bold;
  color: $light-green;
  width: min-content;
  display: grid;
  place-items: center;
  padding: 0 2px 0 12px;
}

.dayAndWeek{
  display: flex;
  padding: 3px 5px;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: column;    
  }
}

.dayChip{
  background-color: fade-out($light-green, 0.8);
  width: 30%;
  height: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  padding: 5px 10px;
  @media (max-width: 991px) {
    width: max-content;
  }
}

.weekChip{
  background-color: fade-out($light-green, 0.8);
  height: 100%;
  display: flex;
  gap: 4px;
  align-items: center;
  border-radius: 4px;
  padding: 5px 10px;
  justify-content: space-evenly;
  @media (max-width: 991px) {
    width: max-content;
  }
}

.dayLabel{
  font-weight: bold;
}

.shifts{
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.shiftsLabel{
}

.shiftsCount{
  font-weight: 500;
}

.allocatedCount{
  font-weight: 500;
}

.incomplete{
  color: $deep-red;
}

.complete{
  color:$light-green;
}

.daySummary{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  @media (max-width: 1260px) {
    flex-direction: column;
    padding: 4px 0 0 0;
  }
}

.weekSummary{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  gap: 4px;

  @media (max-width: 993px) {
    column-gap: 6px;    
  }
}

.dayShiftInfo{
  display: flex;
  gap: 5px;
  align-items: center;
}

.summaryAndDropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: solid 1px $border-bg-dropdown;
  cursor: pointer;
}

.iconArrow{
  font-size: 9px;
  color: $font-dark-grey;
}

