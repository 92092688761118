@import "../../global/colors.scss";

.wrapper{
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: inherit;
  &.right{
    flex-direction: row-reverse;
  }
}

.checkboxContainer {
  position: relative;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  width: 1.3em;
  height: 1.3em;
  input{
    margin: 0;
    padding: 0;
  }
}

.customCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.2em;
  width: 1.2em;
  background-color: transparent;
  border: solid 1px $font-light-grey;
  border-radius: 0.2em;
  transition: background-color 0.3s;
  &.whiteBorder{
    border: solid 1px white;
  }
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 0.3em;
  top: 0.05em;
  width: 0.4em;
  height: 1em;
  border: solid $white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.customCheckbox:checked ~ .checkmark {
  background-color: $light-green;
  border: solid 1px $light-green;
  &.whiteBorder{
    border: solid 1px white;
  }
}

.customCheckbox:checked ~ .checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.customCheckbox:checked ~ .checkmark:after {
  animation: checkAnim 0.2s forwards;
}