@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1em;
  color: $white;
  min-width: 22rem;
}

.active {
  background-color: $light-green;
  border-radius: 5px;
}

.dropdownTitle {
  color: $font-dark-grey;
  font-weight: 600;
  padding-left: 1rem;
}

.light{
  color: $light-green;
}