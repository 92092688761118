@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.confirmText {
    color: $white;
    padding: 1em;
    font-size: 15px;
    text-align: center;
}

.iconClose::before,
.iconDelete::before {
    color: $white;
}

.buttonContainer {
    background-color: $selected-week-day-bg;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
}

.confirmButton {
    display: none;
    padding: 10px;
    background-color: $light-green;
    border: 1px solid $light-green;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 1em;
    color: $white;
    cursor: pointer;

    span {
        padding-right: 5px;

        &::before {
            font-size: 1em;
        }
    }

    @media #{$tablet-up} {
        display: block;
    }

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }
}