@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.weekDaysContainer {
  display: none;
  @media #{$tablet-up} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 0;
    width: 100%;
    border-bottom: 1px solid $grey;
  }
}

.emptySlot {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid $grey;
  justify-content: center;
  position: relative;
}

.slot {
  cursor: pointer;
  align-items: center;
  text-align: center;
  border-right: 1px solid $grey;
}

.iconOptions::before {
  color: $medium-grey;
}

.leaveContainer {
  display: flex;
  flex-direction: column;
}

.leaveSlot {
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid $grey;
  justify-content: center;
  cursor: default;
  color: $font-light-grey;
}

.leaveTitle {
  font-size: 0.8rem;
}

.transferContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  color: $light-green;
  .iconRelocated {
    font-size: 1.3rem;
    margin-right: 0.3rem;
  }
  .label {
    font-size: 0.7rem;
  }
  >i{
    color: $font-light-grey;
    font-size: 0.7em;
    position: absolute;
    top: 0.6em;
    right: 0.6em;
    cursor: pointer;
  }
}