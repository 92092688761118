@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.shiftInfo  {
  display: flex;
  flex-direction: column;
  gap: 0.6em
}

.siteContainer{
  width: 100%;
}

.header {
  display: flex;
  color: $white;
  .title {
    display: flex;
    flex: 1;
    .transferIcon {
      margin-right: 1rem;
      &::before {
        font-size: 1.5rem;
      }
    }
    span {
      display: flex;
      align-items: center;
    }
    .info {
      display: flex;
      flex-direction: column;
    }
    .text {
      font-weight: bold;
    }
    .date {
      font-size: 0.9rem;
      color: $font-dark-grey;
    }
  }

  button {
    border: none;
    padding: 0.7rem;
    border-radius: 0.3rem;
    margin: 0 0.5rem;
    width: 8rem;
    cursor: pointer;
  }
}

.cancelBtn {
  display: none;
  @media #{$tablet-up} {
    display: flex;
    background-color: $dark-grey-bg;
    color: $white;
    justify-content: center;
  }
}

.header .doneBtn {
  background-color: $light-green;
  color: $white;
  width: auto;
  @media #{$tablet-up} {
    width: 8rem;
  }
}

.userContainer {
  background-color: $dark-grey-bg;
  display: flex;
  color: $white;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.7rem 0;
}

.shiftSummarey {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  flex: 10;
  .title {
    color: $font-dark-grey;
  }
}

.dropdownContainer{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1em;
}
// .dropdownContainer {
//   display: block;
//   width: 100%;
//   color: $white;
//   margin-bottom: 2rem;
//   div {
//     flex: 1;
//   }
//   @media #{$tablet-up} {
//     display: flex;
//   }
// }

.titleText {
  color: $font-dark-grey;
  font-weight: 600;
  padding-left: 1rem;
}

.dropdownTitle {
  color: $font-dark-grey;
  font-weight: 600;
  padding-left: 1rem;
}

.header .cancelBtnMobile {
  display: flex;
  background-color: $dark-grey-bg;
  color: $white;
  width: auto;
  align-items: center;
  @media #{$tablet-up} {
    display: none;
  }
}

.buttonsContainer {
  display: flex;
}