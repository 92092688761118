@import "../../global/colors.scss";

.searchBar{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px 8px 10px;
  border-radius: 7px;
  font-family: 'Poppins';
  border: solid 1px $font-light-grey;
  box-sizing: border-box;
}

.customInput{
  font-size: 0.9rem;
  width: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  &.dark{
    color: $white;
  }
}

.searchIcon{
  font-size: 1.1rem;
  width: min-content;
  right: 1.7rem;
  color: $font-light-grey;
}

.clearIcon{
  font-size: 0.8rem;
  width: min-content;
  right: 1.7rem;
  color: $font-light-grey;
  cursor: pointer;
}
