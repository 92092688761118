@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.content {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 14px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.labelContainer {
  display: flex;
  align-items: center;
  gap: 13px;
}

.inputField{ 
  display: flex;
  width: 100%;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $white;
}

.searchIcon{
  font-size: 1.1rem;
  width: 0;
  position: relative;
  right: 2.7rem;
  color: $font-light-grey;
}

.resourceImage {
  font-size: 1.2em;
  font-weight: 600;
  color: $light-green;
}

.openArrow {
  transform: rotate(-45deg);
  color: $light-green;
  font-size: 0.8rem;
}

.closedArrow{
  transform: rotate(135deg);
  color: $font-deep-grey;
  font-size: 0.8rem;
}

.actions{
  display: flex;
  flex-direction: column;
}

.border{
  border: solid 2px $grey;
}

.borderBottom{
  border-bottom: solid 1px $grey;
}

.openHeader{
  background-color: fade-out($light-green, 0.8);
}

.disabledOption{
  background-color: $light-grey;
  color: $font-deep-grey;
  transition: .4s;
}

.action{
  padding: 11px 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.customInput{
  font-size: 0.9rem;
  padding: 8px 34px 8px 15px;
  border-radius: 7px;
  font-family: 'Poppins';
  width: calc(100% - 2*32px);
  outline: none;
  border: solid 1px $font-light-grey;
  margin: 16px;
}