@import "../../global/colors.scss";

.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  >div{
    width: 100%;
    box-sizing: border-box;
  }
  input{
    box-sizing: border-box;
    width: 100%;
  }
}

.menuContent{
  position: absolute;
  top: 100%;
  right: 0;
  visibility: hidden;
  background-color: $white;
  max-height: 90vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: auto;
  border-radius: 0.5em;
  &.open{
    visibility: visible;
  }
}