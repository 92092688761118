@import "../../global/colors.scss";

.sortableList{
  padding: 0 1em;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}


.dropPlaceholder{
  background-image: radial-gradient(
    circle at center,
    $medium-grey 0.05rem,
    transparent 0
  );
  background-size: 0.5rem 0.5rem;

  >.item {
    opacity: 0;
  }
}

.grab:active{
  cursor: pointer;
}
