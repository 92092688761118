@import "../../global/colors.scss";

.userCard{
  padding: 0.4em 1em 0.35em;
  border-radius: 6px;
  color: $white;
  font-weight: normal;
  font-size: 0.85em;
  background-color: $light-green;
  white-space: nowrap;
}

.userList{
  display: flex;
  align-items: center;
  gap: 0.4em;
  max-width: 100%;
}

.userCard.usersOverflow{
  background-color: $light-green;
  padding: 0.4em 0 0.3em 0;
  min-width: 2.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.tooltip{
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  font-family: 'Poppins';
  font-weight: normal;
}

.userCard.placeholder{
  background-color: transparent;
  color: $font-dark-grey;
  font-weight: bold;
  font-style: italic;
}