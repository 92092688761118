@import "../../global/colors.scss";

.card{
  color: white;
  height: 100%;
  width: 30rem;
  padding: 1em;
  border-radius: 2em;
  background-color: black;
  display: flex;
  flex-direction: column;
  gap: 1em;
  box-sizing: border-box;

  >span {
    font-size: 1rem;
    font-weight: bold;

  }
}

.cardContent {
  // background-color: rgba(0, 255, 115, 0.5);
  display: grid;
  grid-template-columns: auto auto;
  // height: calc(100% - 2em);
  /* Adjusted height */
  box-sizing: border-box;
  /* Include padding in height calculation */
  border-radius: 0.6em;
  gap: 0.5em;
  padding: 0.3em;
  overflow: auto;
  /* Enable scrollbars when content overflows */
}

.cardContent>span {
  border-radius: 0.5em;
  background-color: $fade-white;
  padding: 0.7em;
}

.modal{
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.modalContent{
  display: flex;
}

.dropdown{
  border: solid 1px magenta;
}

.container {
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: min-content;
}

.dialogHeader {
  display: flex;
  align-items: flex-start;
  gap: 1em;
  justify-content: space-between;

}

.headerButtons{
  display: flex;
  align-items: center;
  gap: 0.4em;
  >button {
    height: 2.7em;
    color: $white;
    border-radius: 0.5em;
    cursor: pointer;
    &.save{
      padding: 0em 3em;
      background-color: $light-green;
      font-weight: 500;
    }
    &.iconButton{
      width: 2.7em;
      background-color: $dark-menu;
      display: grid;
      place-items: center;
      >i[class*='clean'] {
        font-size: 1.2em;
      }
    }
  }
}

.headerInfo {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  > h2 {
    font-size: 0.7em;
    color: $font-dark-grey;
    margin: 0;
    font-weight: normal;
    line-height: 1.1;
  }
}

.iconTitle{
  display: flex;
  align-items: center;
  gap: 0.5em;
  > i {
    font-size: 1.5em;
    color: $light-green;
  }
  > h1 {
    font-size: 1.2em;
    margin: 0;
    font-weight: bold;
    white-space: nowrap;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  height: 100%;
}

.sitesArray{
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  overflow-y: auto;
  gap: 0.8em;
}

.deleteIcon {
  font-size: 1.2em;
  background-color: $black;
  color: $white;
  // padding: 0.7rem;
  height: 2em;
  width: 2em;
  border-radius: 0.5rem;
  display: none;
  & [class*="icon-required-shifts"] {
    font-size: 1.2em;
  }
}
