@import "../../global/colors.scss";
@import "../../global/media_query_variables.scss";

.crew {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 6px 15px;
  border-radius: 7px;
  background-color: $light-grey;
  border: solid 1px $grey;
  cursor: pointer;
  transition: .2s;
}

.dragIcon{
  color: $medium-grey;
  cursor: grab;
}

.crew:hover{
  background-color: fade-out($light-green, 0.86);
  border: solid 1px fade-out($light-green, 0.86);
  transition: .2s;
}

.crew.selectedCrew{
  background-color: fade-out($light-green, 0.86);
  border: solid 1px $light-green;
}

.crewIcon {
  font-size: 1.7em;
  color: $light-green;
}

.crewInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.crewName {
  font-weight: bold;
}

.deleteIcon {
  color: $white;
  display: grid;
  place-items: center;
  margin-top: 3px;
}

.deleteButton{
  background-color: $black;
  padding: 10px;
  border-radius: 6px;
}