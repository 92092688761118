@import '../../global/colors.scss';

.formBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: $white;
  font-weight: bold;
  background-color: $black;
  width: min-content;
  white-space: nowrap;
  padding: 0 1.5em;
  height: 3em;
  border-radius: 7px;
  cursor: pointer;

  &.saveBtn{
    padding: 0 3.2em;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }

}

.dialogContent{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.7em;
  height: 30rem;
  max-height: 85vh;
  box-sizing: border-box;
  button{
    font-size: 0.85rem;
    display: grid;
    place-items: center;
    border: none;
    min-height: 2.5em;
    min-width: 2.5em;
    background-color: $fade-white;
    border-radius: 0.3em;
    color: $white;
    font-weight: 500;
    cursor: pointer;

    &.greenButton{
      background-color: $light-green;
    }

    &.textButton{
      padding: 0 3em;
    }
  }
}

.rightPanel{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  height: 100%;
  box-sizing: border-box;
}

.placeholder{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 2em;
  height: 100%;
  box-sizing: border-box;
  >i{
    font-size: 5em;
    color: fade-out($light-green, 0.7);
  }
  >span{
    font-size: 0.8em;
    text-align: center;
  }
}

.dialogButtons{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1em;
}

.dialogHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1em;
}

.schedules{
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
}

.schedule{
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  gap: 1em;
  background-color: $black;
  border-radius: 0.4em;
  border: solid 1px $black;
  padding: 0.5em 0.5em 0.5em 1em;
  cursor: pointer;
  >i {
    font-size: 1.3rem;
    color: $font-light-grey;
  }

  h1{
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
  }
  h2{
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    color: $font-light-grey;

  }
  button{
    background-color: transparent;
    min-width: 2em;
    padding: 0;
    width: min-content;
  }

  &.highlight{
    border: solid 1px $light-green;
    background-color: fade-out($light-green, 0.93);
    > i, h2{
      color: $light-green;
    }
  }
}

.scheduleButtons{
  display: flex;
  align-items: center;
  gap: 0.1em;
}

.iconTitle{
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-left: 0.7em;
  >i{
    font-size: 1.3rem;
  }
  >span{
    white-space: nowrap;
    font-weight: bold;
  }
}

.card{
  background-color: $fade-white;
  border-radius: 0.4em;
  padding: 0.3em;
  width: 20rem;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
}

.scheduleInfo{
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.buttons{
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0 1em 1em 1em;
  >button{
    width: 100%;
    padding: 0;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
  }
}

.startTime {
  color: $light-green;
}

.endTime{
  color: $red
}