@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.noDataContainer {
  display: flex;
}

.container {
  display: flex;
  align-items: center;
  border-right: 1px solid $empty-slot-grey-color;
  width: 100%;
  flex-direction: column;
  padding: 0px 15px;
  justify-content: center;
  @media #{$tablet-up} {
    width: 34vw;
  }
}

.textContainer {
  background-color: $shift-pattern-bg;
  padding: 15px;
  border-radius: 10px;
  margin-top: 2rem;
}

.weekRowContainer {
  display: none;
  @media #{$tablet-up} {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.weekDaysContainer:nth-child(odd) {
  background-color: $light-grey;
}

.weekDaysContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0;
  width: 100%;
}

.emptySlot {
  color: $empty-slot-dark-grey-color;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  border-right: 1px solid $empty-slot-grey-color;
  border-bottom: 1px solid $empty-slot-grey-color;
  justify-content: center;
  height: 100px;
}
