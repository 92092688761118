@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.BottomBar {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 70px;
  background-color: $dark-black;
  color: $white;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;
  cursor: pointer;
  z-index: 2;

  @media #{$tablet-up} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    height: 50px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  @media #{$mobile-query} {
    position: fixed;
    top: 200px;
    width: 100%;
  }
}

.dateContainer {
  display: grid;
  place-content: center;
  text-align: center;
  height: 100%;

  .hideCellsLeft {
    height: 100%;
  }

  .hideCellsRight {
    height: 100%;
  }

  &:first-child {
    border-left: solid 2px $divisor-line;
  }

  &:nth-child(2n) {
    border-right: solid 2px $divisor-line;
  }

  &:nth-child(2n-1) {
    border-right: solid 2px $dark-grey-bg;
  }

  @media #{$tablet-up} {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .month {
    font-style: oblique;
    font-weight: 100;
    white-space: nowrap;
    // overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
  }

  .day {
    font-weight: bold;
  }
}

.selectedDay {
  border-top: 3px solid $light-green;
  background-color: $selected-week-day-bg;
  height: calc(100% - 3px);
  align-items: center;
  justify-content: center;

  @media #{$tablet-up} {
    justify-content: space-evenly;
  }
}

@media (max-width: 1293px) {
  .BottomBar {
    grid-template-columns: repeat(9, 1fr);
  }

  .hideCellsRight:last-child {
    display: none;
  }

  .hideCellsLeft:first-child {
    display: none;
  }
}

@media (max-width: 1060px) {
  .BottomBar {
    grid-template-columns: repeat(7, 1fr);
  }

  .hideCellsRight:nth-last-child(-n + 2) {
    display: none;
  }

  .hideCellsLeft:nth-child(-n + 2) {
    display: none;
  }
}

@media (max-width: 1060px) {
  .BottomBar {
    grid-template-columns: repeat(5, 1fr);
  }

  .hideCellsRight:nth-last-child(-n + 3) {
    display: none;
  }

  .hideCellsLeft:nth-child(-n + 3) {
    display: none;
  }
}

@media (max-width: 380px) {
  .BottomBar {
    grid-template-columns: repeat(3, 1fr);
  }

  .hideCellsRight:nth-last-child(-n + 4) {
    display: none;
  }

  .hideCellsLeft:nth-child(-n + 4) {
    display: none;
  }
}
