@import '../../global/colors.scss';

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.dark {

  input,
  textarea {
    color: $white;
    border: solid 2px $border-bg-dropdown;
  }

  span {
    border: solid 2px $border-bg-dropdown;
  }
}

.light {
  color: $font-deep-grey;
  input,
  textarea {
    color: $font-deep-grey;
    border: solid 2px $grey;
  }
  span {
    border: solid 2px $grey;
  }
  i{
    font-weight: bold;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  >i {
    position: absolute;
    display: grid;
    place-items: center;
    font-size: 1.2rem;
    left: 0.5em;

    &[class*='check']::before {
      font-size: 0.7em;
    }

  }

  &.right {
    input {
      padding-right: 3rem;
    }

    >i {
      left: unset;
      right: 0.7em;
    }
  }

  &.left {
    input {
      padding-left: 3rem;
    }
  }

  >span {
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 0.3rem;
    cursor: pointer;
  }

}

.field {
  font-size: 1rem;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  width: 100%;

  &.right {
    align-items: flex-end;

    input {
      text-align: right;
    }

    ;
  }

  &.color {
    flex-direction: row-reverse;
    align-items: center;
    gap: 1rem;

    >.inputWrapper {
      width: min-content;
    }

    >label {
      padding: 0;
    }
  }

  input {
    font-size: 1rem;
    height: 2.8rem;
    padding: 0 1rem;
    font-weight: bold;
    text-align: left;
  }

  input::placeholder {
    font-size: 0.9rem;
  }

  textarea {
    font-size: 0.87rem;
    height: 6rem;
    padding: 0.5rem 1rem;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-weight: normal;
    resize: none;

  }

  input,
  textarea {
    width: 100%;
    box-sizing: border-box;
    outline: none;
    background-color: transparent;
    border-radius: 0.4rem;

    &[type="color"] {
      visibility: hidden;
      padding: 0;
      margin: 0;
      width: 0;
      height: 0;
    }

    &[type="date"] {
      cursor: pointer;
    }


    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  >label {
    font-size: 1rem;
    font-weight: bold;
    color: $font-light-grey;
    padding: 0 1rem;
  }

  &.focused {
    color: $light-green;

    input,
    textarea {
      border: solid 2px $light-green;
      background-color: fade-out($light-green, 0.93);
    }

    label,
    i {
      color: $light-green;
    }
  }
}