@import "../../global/media_query_variables.scss";
@import "../../global/colors.scss";

.header{
  border-bottom: solid 1px $medium-grey;
  display: flex;
  align-items: center;
  padding: 0 20px;
  gap: 15px;
}

.roleActionsContainer{
  height: calc(100% - 56px);
}

.tab{
  padding: 13px 13px;
  font-weight: 500;
  color: $font-deep-grey;
  font-size: 1rem;
  cursor: pointer;
  border-bottom: solid 3px transparent;
}

.lowOpacity{
  opacity: 0.5;
  pointer-events: none;
}

.activeTab{
  border-bottom: solid 3px $light-green;
}

.tab:hover{
  border-bottom: solid 3px $light-green;
}

.body{
  overflow-y: auto;
  max-height: 100%;
}