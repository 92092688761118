@import "../../global/colors.scss";


.wrapper{
  width: 100%;
  position: relative;
  >input{
    cursor: pointer;
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0;
  span {
    flex: 1;
    font-weight: 400;
    font-size: 1rem;
  }
  button {
    background-color: $light-green;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    color: $white;
    font-size: 1rem;
    cursor: pointer;
  }
}