@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.disabled {
  opacity: 0.6;
  cursor: default;
  & * {
    cursor: default !important;
  }
}

.disabledPlaceholder{
  font-style: italic;
  // color: $white;
  width: 100%;
}

.locationDropdown {
  border-radius: 5px;
  height: 55px;
  display: flex;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  background-color: $black;
  color: $white;

  @media #{$tablet-up} {
    height: 38px;
    margin-left: 0.5em;
  }

  &.locationDropdownComponent {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

.outlined{
  border: solid 1px $dark-grey;
}

.dropdown {
  position: relative;
  padding: 10px;
  display: flex;
  width: 100%;
  h3{
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    font-weight: normal;
    text-align: center;
    width: 100%;
    font-style: italic;
    color: $font-dark-grey;


  }
}

.dropdownToggle {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.7em;
  width: 100%;
  appearance: none;
}

.dropdownMenu {
  position: absolute;
  margin-top: 3px;
  top: 100%;
  background-color: $black;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: min-content;
  min-width: 100%;
  padding-left: 0;
  max-height: 400px;
  overflow-y: auto;
  z-index: 20;
  overflow-x: hidden;
}

.dropdownMenu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: 0.5 ease;
}

.dropdownMenu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: 0.5 ease;
}

.dropdownToggle input {
  display: flex;
  padding: 8px;
  width: 100%;
  border-radius: 10px;
  border: none;
  background: transparent;
  &:focus {
    outline: none;
    border:none;
  }
  &.light{
    color: $black;
  }
  &.dark{
    color: $white;
  }
}

.location {
  display: flex;
  align-items: center;
}

.location::before {
  color: $light-green;
  font-size: 1.4em;
}

.checkIcon {
  display: flex;
  margin-right: 5px;
}

.checkIcon::before {
  color: $light-green;
  font-size: 0.7em;
}

.selectedItemText {
  flex: 8;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 30px;
}

.dropdownArrow {
  font-size: 0.6em;
  display: flex;
  align-items: center;
}

.monthDropdown {
  width: 50%;
  margin-right: 10px;

  @media #{$tablet-up} {
    width: 15%;
    margin-right: 0px;
  }
}

.yearDropdown {
  width: 50%;
  margin-right: 0;

  @media #{$tablet-up} {
    width: 15%;
    margin-right: 1em;
  }
}

ul {
  padding-left: 0px;
  margin: 0;
}

.optionsList {
  list-style: none;
}

p,.optionLabel {
  display: block;
  padding: 10px 20px;
  color: white;
  margin: 0;
}

p:hover, .optionLabel:hover {
  background-color: $light-green;
  cursor: pointer;
}

.dropdownMenu::-webkit-scrollbar {
  width: 5px;
}

.dropdownMenu::-webkit-scrollbar-track {
  background-color: transparent;
}

.dropdownMenu::-webkit-scrollbar-thumb {
  background-color: $scroll-bar-bg;
  border-radius: 10px;
}

.sitesDropdown {
  margin: 0;
  margin-top: 0.5em;
  height: 2.8rem;
  border: 2px solid $border-bg-dropdown;
  border-radius: 0.4rem;
  margin-top: 0px;
}

.sitesDropdownMiddle {
  margin: 0;
  margin-top: 0.5em;
  height: 2.8rem;
  border: 2.6px solid $border-bg-dropdown;
  border-radius: 10px;
  width: 45%;
}

.sitesDropdownShort {
  margin: 0;
  margin-top: 0.5em;
  height: 2.8rem;
  border: 2.6px solid $border-bg-dropdown;
  border-radius: 10px;
  width: 38%;
}


.sitesDropdown .dropdown {
  padding: 10px 20px;
}

.userDropdownContainer {
  background-color: $black;
}

.userDropdownContainer ul li:hover {
  background-color: $light-green;
  cursor: pointer;
}

.userList {
  padding: 10px;
}

.userListDropdown {
  height: 3.5rem;
  padding: 5px 0px;
  margin: 0;
  margin-top: 0.5rem;
  background-color: $black;
  border: none;
  border-radius: 10px;
}

.userListDropdown .dropdown {
  padding-right: 25px;
}

.sitesDropdownContainer {
  background-color: $bg-modal;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.sitesDropdownContainer p {
  color: $white;
}

.leavesDropdown {
  padding: 0;
  width: 100%;
  border: 2px solid $border-bg-dropdown;
  margin: 0;
  height: 2.8rem;

  .dropdown {
    padding: 0 1em;
  }

  .dropdownToggle {
    display: flex;
    flex-direction: row;
    margin: 0;
  }
}

.shiftPatter {
  margin: 0;
  height: 48px;
  border: 2.6px solid $grey;
  border-radius: 10px;
  background-color: $white;
  color: $black;
}

.yearDropDown {
  border-radius: 5px;
  height: 55px;
  width: 70px;
  display: flex;
  margin: auto;
  background-color: $bg-modal;

  @media #{$tablet-up} {
    height: 38px;
    margin-left: 0.5em;
  }
}

.monthViewDropdown {
  border-radius: 5px;
  height: 55px;
  display: flex;
  margin: auto;
  width: 95%;
  background-color: $bg-modal;

  @media #{$tablet-up} {
    height: 38px;
  }
}

.locationForm {
  margin-left: 0;
  height: 2.5em;
  width: 100%;
  background-color: $white;
  border: 1px solid $font-light-grey;
  color: $grey;

  .dropdownArrow {
    color: $font-light-grey;
  }

  .selectedItemText {
    color: $black;
  }
}