@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.dayCardContainer {
  background-color: $black;
  color: $shift-pattern-grey-color;
  width: 100%;

  box-sizing: border-box;
  padding: 1rem;
  min-height: 14rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  .day {
    flex: 1;
    span {
      color: $white;
      font-weight: bold;
    }
  }
  .infoText {
    flex: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
		gap: 12px;
    .infoIcon {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }
    h6 {
      margin: 0;
      padding: 0;
      font-size: 0.7rem;
    }
  }
}

.selectedItemcontainer {
  background-color: $row-hover-bg;
}

.container {
  display: flex;
  position: relative;
  padding-left: 2rem;
  color: $white;
  font-weight: bold;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.selectedItemcontainer {
  .container {
    color: $black;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: 1px solid $font-dark-grey;
  border-radius: 5px;
}


/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $light-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
