@import "../../global/colors.scss";

.confirmationMessage {
  font-size: 1em;
  text-align: center;
  margin-bottom: 20px;
  color: white;
  display: flex;
  place-content: center;
}

.confirmationButtons {
  display: flex;
  justify-content: space-around;
  gap: 0.4em;
}

.confirmButton,
.cancelButton {
  background-color: $light-green;
  border: none;
  border-radius: 4px;
  color: $btn-color;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition-duration: 0.4s;
  width: 40%;
}