@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?x0hlbp');
  src:  url('fonts/icomoon.eot?x0hlbp#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x0hlbp') format('truetype'),
    url('fonts/icomoon.woff?x0hlbp') format('woff'),
    url('fonts/icomoon.svg?x0hlbp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-travel:before {
  content: "\e944";
}
.icon-check:before {
  content: "\e900";
}
.icon-user-card:before {
  content: "\e901";
}
.icon-move:before {
  content: "\e902";
}
.icon-search:before {
  content: "\e903";
}
.icon-calendar:before {
  content: "\e904";
}
.icon-logo:before {
  content: "\e905";
}
.icon-location:before {
  content: "\e906";
}
.icon-user:before {
  content: "\e907";
}
.icon-draft:before {
  content: "\e908";
}
.icon-flag:before {
  content: "\e909";
}
.icon-information:before {
  content: "\e90a";
}
.icon-delete:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-plane:before {
  content: "\e90d";
}
.icon-menu:before {
  content: "\e90e";
}
.icon-up:before {
  content: "\e90f";
}
.icon-down:before {
  content: "\e910";
}
.icon-options:before {
  content: "\e911";
}
.icon-password:before {
  content: "\e916";
}
.icon-user-admin:before {
  content: "\e917";
}
.icon-filter:before {
  content: "\e912";
}
.icon-more:before {
  content: "\e913";
}
.icon-less:before {
  content: "\e914";
}
.icon-overtime .path1:before {
  content: "\e915";
  color: rgb(255, 255, 255);
}
.icon-overtime .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(26, 26, 26);
}
.icon-vacation:before {
  content: "\e919";
}
.icon-leave:before {
  content: "\e91a";
}
.icon-sick:before {
  content: "\e91b";
}
.icon-training:before {
  content: "\e91c";
}
.icon-permission:before {
  content: "\e91d";
}
.icon-comment:before {
  content: "\e91e";
}
.icon-config:before {
  content: "\e91f";
}
.icon-transfer-user:before {
  content: "\e920";
}
.icon-icon-relocated:before {
  content: "\e921";
}
.icon-user-shift-pattern:before {
  content: "\e922";
}
.icon-users:before {
  content: "\e923";
}
.icon-information1:before {
  content: "\e924";
}
.icon-right-arrow:before {
  content: "\e925";
}
.icon-user-calendar:before {
  content: "\e926";
}
.icon-left-arrow:before {
  content: "\e927";
}
.icon-right-arrow1:before {
  content: "\e928";
}
.icon-folder-plus:before {
  content: "\e929";
}
.icon-save:before {
  content: "\e92a";
}
.icon-folder:before {
  content: "\e92b";
}
.icon-role:before {
  content: "\e92c";
}
.icon-role-plus:before {
  content: "\e92d";
}
.icon-folder-open:before {
  content: "\e92e";
}
.icon-error:before {
  content: "\e92f";
}
.icon-email:before {
  content: "\e930";
}
.icon-phone:before {
  content: "\e931";
}
.icon-overtime-clock:before {
  content: "\e932";
}
.icon-assigned-role:before {
  content: "\e933";
}
.icon-circle:before {
  content: "\e934";
}
.icon-site:before {
  content: "\e935";
}
.icon-add-site:before {
  content: "\e936";
}
.icon-app-logo:before {
  content: "\e937";
}
.icon-leave-admin:before {
  content: "\e938";
}
.icon-microsoft-logo .path1:before {
  content: "\e939";
  color: rgb(242, 80, 34);
}
.icon-microsoft-logo .path2:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(0, 164, 239);
}
.icon-microsoft-logo .path3:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(127, 186, 0);
}
.icon-microsoft-logo .path4:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(255, 185, 0);
}
.icon-squarebadge:before {
  content: "\e93d";
}
.icon-time-changed:before {
  content: "\e93e";
}
.icon-add-schedule:before {
  content: "\e93f";
}
.icon-check-schedule:before {
  content: "\e940";
}
.icon-required-shifts:before {
  content: "\e941";
}
.icon-clean:before {
  content: "\e942";
}
.icon-refresh:before {
  content: "\e943";
}
