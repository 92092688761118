@import "../../global/media_query_variables.scss";

.headerMainContainer {
  width: 100%;
}

.headerTopNoMarginBottom {
  margin-top: 270px;
  margin-bottom: 0px;
  @media #{$tablet-up} {
    margin-top: 110px;
    margin-bottom: 0;
  }
}