@import "../../global/media_query_variables";
@import "../../global/colors.scss";

.window {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $light-grey;
  overflow: hidden;
}

.containers {
  margin-top: 5.25em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  gap: 1.5em;
  margin-bottom: 1.5em;
}

.leftContainer {
  background-color: $white;
  width: 35%;
  margin-left: 1.5em;
  border-radius: 10px;
  box-shadow: 0 2px 4px $font-dark-grey;
  overflow-y: auto;
}

.leftContainerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin: 1em;
}

.searchInput {
  display: flex;
  width: 100%;
  align-items: center;
}

.searchIcon {
  font-size: 1.1rem;
  width: 0;
  position: relative;
  right: 1.7rem;
  color: $font-light-grey;
}

.inputUser {
  height: 2.8em;
  width: 100%;
  background-color: $white;
  border: 1px solid $font-light-grey;
  border-radius: 7px;
  padding-left: 1em;
  padding-right: 2.5rem;
  outline: none;
  font-family: "Poppins";
  font-size: 14px;

  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.newUserButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: $white;
  font-weight: bold;
  background-color: $black;
  width: 15em;
  height: 3em;
  border-radius: 7px;
  cursor: pointer;
}

.emptyUsers {
  padding-left: 2em;
  font-size: 15px;
  color: $font-dark-grey;
}

.cardsContainer {
  overflow-y: auto;
  max-height: calc(90vh - 100px);
}

.userCard {
  border-radius: 7px;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.5em 1em 0.2em 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 1px $font-light-grey;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.userCard:nth-child(even) {
  background-color: $white;
}

.userCard:nth-child(odd) {
  background-color: $light-grey;
}

.userCard.selected {
    border: solid 2px $light-green;
}

.leftCardInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.shortNameContainer {
  width: 2em;
  height: 2em;
  background-color: $default-card-bg;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 2px;
}

.emailIcon {
  font-size: 0.8rem;
  margin-right: 0.25em;
  color: $font-deep-grey;
  margin-top: 3px;
}

.phoneIcon {
  font-size: 0.8rem;
  color: $font-deep-grey;
  margin-top: 3px;
}

.skills {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.2em;
  margin-bottom: 0.25em;
}

.skillBullet {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
}

.topcardInfo {
  margin-bottom: -2px;
}

.bottomCardInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userName {
  color: $black;
  font-size: 14px;
  margin-right: 0.7em;
}

.userNumber {
  color: $font-dark-grey;
  font-size: 12px;
}

.userId {
  font-weight: bold;
  color: $font-dark-grey;
  font-size: 12px;
}

.userEmailAndPhone {
  color: $font-dark-grey;
  font-size: 12px;
  margin-right: 1em;
}

.userInfo{
  margin-bottom: 0.5em;
}

.rightContainer {
  background-color: $white;
  width: 65%;
  margin-right: 1.5em;
  border-radius: 10px;
  box-shadow: 0 2px 4px $font-dark-grey;
}

.rightContainerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 1em 1em 1em;
}

.headerInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5em;
}

.headerInfoBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.editIcon {
  transform: rotate(30deg);
  margin-right: 0.1em;
  font-size: 1.3rem;
}

.editText {
  color: $black;
  font-weight: bold;
}

.roleAndSaveButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  color: $white;
  font-weight: bold;
  background-color: $black;
  width: 9em;
  height: 3em;
  border-radius: 7px;
  cursor: pointer;

  &.disabled {
    opacity: 0.4;
    cursor: default;
    pointer-events: none;
  }
}

.roleIcon {
  font-size: 1.3rem;
}

.linkToEmployeeContainer {
  margin-left: 1em;
  margin-right: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $light-grey;
  border-radius: 15px;
  margin-bottom: 1em;
  padding: 0.1em 2em 0.1em 2em;

  &.active {
    background-color: $bg-toggle-btn;
  }

  &.inactive {
    background-color: $light-grey;
  }
}

.linkEmployeeText {
  color: $black;
  font-weight: bold;
  font-size: 0.9em;
}

.linkToEmployeeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.selectEmployeeContainer {
  width: 100%;
}

.employeeSelectInfo{
  width: 70%;  
  display: flex;
  flex-direction: column;
  gap: 0.1em;
  >span{
    font-size: 0.8em;
    font-weight: 500;
    padding-left: 1rem;
  }
}

.hide{
  opacity: 0;
  transition: 0.4s;
}

.show{
  opacity: 1;
  transition: 0.4s;
}

.selectEmployeeText {
  color: $border-bg-dropdown;
  font-weight: bold;
  font-size: 14px;
  margin-left: 1.1em;
}

.employeeListContainer {
  overflow-y: auto;
  max-height: calc(90vh - 100px);
}

.shortNameInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  margin-top: 3px;
  margin-left: -10px;
}

.shortNameContainer2 {
  width: 2em;
  height: 2em;
  background-color: $default-card-bg;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 2px;
}

.menuName {
  font-size: 14px;
  font-family: "poppins";
}

.fourInputsRow{
  display: flex;
  flex-direction: row;
  padding: 0 1em 0.5em;
  align-items: center;
  gap: 1em;
}

.field{
  display: flex;
  flex-direction: column;
  gap: 0.2em;
  flex: 1;
  min-width: calc(25% - 1em);
  >.label{
    margin-left: 1em;
  }
}

.inputsLabels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 2em;
  margin-right: 1em;
  gap: 2.5em;
}

.label {
  color: $border-bg-dropdown;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
}

.mandatory {
  color: $red;
  font-weight: bold;
}

.inputsContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 0.5em;
  gap: 1.5em;
}

.input2 {
  height: 2.7em;
  width: 100%;
  background-color: $white;
  border: 1px solid $font-light-grey;
  border-radius: 7px;
  outline: none;
  padding-left: 1.5em;
  font-family: "poppins";
  font-size: 14px;

  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.input3 {
  width: 100%;
  height: 2.7em;
  background-color: $white;
  border: 1px solid $font-light-grey;
  border-radius: 7px;
  outline: none;
  padding-left: 1.5em;
  font-family: "poppins";
  font-size: 14px;

  @media (orientation: portrait) and (min-width: $mobile) {
    margin-top: 10px;
  }
}

.threeInputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  width: 100%;
}

.input1{
  height: 2.7em;
  background-color: $white;
  border: 1px solid $font-light-grey;
  border-radius: 7px;
  outline: none;
  padding: 0 1em;
  font-family: 'Poppins';
  font-size: 14px;
}

.activeContainer {
  width: 90%;
  height: 4em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  color: $black;
  font-weight: bold;
  border-radius: 15px;

  &.active {
    background-color: $bg-toggle-btn;
  }

  &.inactive {
    background-color: $light-grey;
  }
}

.menuSearchBar{
  margin: 0.5em 2em;
  padding: 0em;
  position: sticky;
  top: 0.5em;
  background-color: white;
  box-shadow:  0px 6px 4px $white;
  z-index: 3;
}
