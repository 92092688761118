@import "../../global/colors.scss";

.container {
  display: flex;
  align-items: center;
  color: $white;
}


.dateInfoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  .title {
    font-weight: bold;
  }

  .date {
    color: $font-light-grey;
    font-style: oblique;
  }
}

.buttonContainer button {
  background-color: $bg-modal;
  border: none;
  margin-right: 7px;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  cursor: pointer;
}

.closeModal {
  margin-right: 0;
}

.iconClose::before,
.iconDelete::before {
  color: $white;
}

.iconClose::before {
  font-size: smaller;
}

.userInfoTitle {
  font-weight: bold;
  color: $font-light-grey;
  margin-top: 10px;
  padding-left: 15px;
  margin-bottom: -15px;
}

.siteContainer {
  display: block;
  margin-top: 5px;
  color: $font-light-grey;
  font-weight: 600;
}

.timesHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10em;
}

.dropdownsPosition {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  width: 100%;
  margin-top: .5em;
}

.dropdownTitle {
  font-weight: bold;
  padding-left: 15px;
  margin-bottom: 10px;
}

.saveBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  gap: 1em;

  button {
    height: 3em;
    width: 10em;
    border-radius: 5px;
    background-color: $light-green;
    color: $white;
    font-weight: bold;
    font-size: 1em;
    cursor: pointer;
  }
}

.isItemSelected {
  background-color: $filter-input-hover-bg;
  border: 1px solid $light-green;
  border-radius: 5px;
}

.overtimeCheckContainer {
  color: $white;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  gap: 1em;
}

.overtime {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: .5em;
  padding-bottom: .5em;
  width: 10em;
}


.overtimeContainer {
  display: flex;
  position: relative;
  padding-left: 2rem;
  cursor: pointer;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: transparent;
  border: 1px solid $font-dark-grey;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.overtimeContainer input:checked~.checkmark {
  background-color: $light-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.overtimeContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.overtimeContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.textArea {
  margin-top: 23px;
  background-color: $dark-grey-bg;
  color: $white;
  width: 98%;
  border-radius: 10px;
}

.textArea::placeholder {
  padding: .25em;
  color: $font-dark-grey;
}

.textAreaLimit {
  color: $white;
  font-size: 12px;
  margin-top: 0px;
  margin-left: 5px;
  margin-bottom: -5px;
}

.dropdownWidth {
  width: 50%;
}

.timeField{
  color: $white;
}

.custom-input {
  outline: none;
  color: $white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Start with a slight upward movement */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* End with no vertical movement */
  }
}

.editTimeInfo {
  display: flex;
  flex-direction: column;
  gap: 1em;
  animation: fadeIn 0.5s ease;
}
