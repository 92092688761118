@import "../../global/colors.scss";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: $modal-bg;
}

.content{
  overflow: hidden;
}


.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 440px;
  background-color: $black;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  animation: modal-fade-in 0.3s ease-out, modal-slide-down 0.3s ease-out forwards;
  max-height: 90%;
}

.disabledContent{
  opacity: 0.5;
}

.overflowAuto{
  overflow: auto;
}

.employeeInfoContainer {
  max-width: 40rem;
  padding-bottom: 1.5em;
}


.shiftPatternContainer {
  background-color: $white;
  color: $black;
  width: 100%;
  max-width: 80rem;
  max-height: 80vh;
  height: 77vh;
}

.scheduleCreation{
  font-size: 1rem;
  color: $white;
  max-width: 90vw;
  width: max-content;
}

.locationShifts{
  font-size: 1rem;
  color: $white;
  max-width: 90vw;
  width: min-content;
  display: flex;
}

.commentsContainer {
  width: 600px;
  height: 590px;
  z-index: 2000;
  max-width: 700px;
}

.deleteCommentsPrompt{
  z-index: 2002;
  border: solid 1px $border-bg-dropdown;
}

@keyframes modal-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modal-slide-down {
  // from {
  //   transform: translate(-50%, -70%);
  // }
  // to {
  //   transform: translate(-50%, -50%);
  // }
}