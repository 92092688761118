$black: #1a1a1a;
$dark-black: #000000;
$white: #ffffff;
$grey: #e6e6e6;
$light-grey: #f7f7f7;
$clear-grey: #f1f1f1;
$medium-grey: #cccccc;
$selected-week-day-bg: #2a2a2a;
$dark-menu: #2a2a2a;
$light-green: #00ca9a;
$row-hover-bg: #d9f7f0;
$font-light-grey: #b3b3b3;
$dark-grey: #808080;
$font-dark-grey: #808080;
$font-deep-grey: #6d6d6d;
$border-bg-dropdown: #4d4d4d;
$bg-modal: #262626;
$filter-input-hover-bg: #223631;
$border-menu: #393434;
$online-button-bg: #8BC34A;
$dark-grey-bg: #272727;
$red: #FF7B7B;
$shift-pattern-title-color: #666666;
$shift-pattern-bg: #f2f2f2;
$shift-pattern-day-title-color: #707070;
$shift-pattern-info-color: #727272;
$shift-pattern-grey-color: #797979;
$shift-pattern-light-grey-bg: #f6f6f6;
$shift-pattern-span-color: #878c8b;
$scroll-bar-bg: #888;
$empty-slot-grey-color: #ccc;
$empty-slot-dark-grey-color: #aaa;
$modal-bg: #fafafa80;
$btn-color: #ffffff;
$bg-toggle-btn: #d9f7f0;
$default-card-bg: #add8e6;
$comment-bg: #223631;
$divisor-line: #ff6347;
$switch-btn-background: #ccc;
$black-btn: #262626;
$blue: #4b9ce0;
$orange: #f9882e;
$deep-red: #ff4162;
$pale-green: #CCF5EB;
$border-card: #ededed;
$microsoft-black: #2F2F2F;
$fade-white: fade-out($white, 0.93);
$dark-faded-green: #005943;
$deep-faded-green: #001E17;
